import React, { Fragment, useContext, useEffect} from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import NewsDetails from './NewsDetails';
import NewsArchive from './NewsArchive';
import Spinner from 'react-bootstrap/Spinner';
import { NewslistContext } from './NewslistContext';
import { PaginationContext } from '../contexts/PaginationContext';
import KwPagination from '../../atomic/organisms/KwPagination';

const NewsListProvider = (props) => {
    const langFiles = 'navbar';
    const { t } = useTranslation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let newsArchive = urlParams.get("arc") === '1';
    const { data, isLoading, isError, changeData } = useContext(NewslistContext);
    const { section, setSection } = useContext(PaginationContext);
    const maxRows = 5;
    !section && setSection(1);

    useEffect(() => {
        changeData(
            [
                {
                    name: 'maxRows',
                    value: maxRows
                },
                {
                    name: 'showArcive',
                    value: newsArchive
                },
                {
                    name: 'section',
                    value: section
                }
            ]
        );
    }, [newsArchive, changeData, section]);

    return (
        <Fragment>
            {isError ? (
                <div>
                    {t('could_not_retrieve_data')}
                </div>
            )   : (
                isLoading && !isError ? (
                    <div>
                        <Spinner animation="border" variant="secondary" size="sm" /> {t('retrieving_news')}
                    </div>
                ) : (
                    <div>
                        <div className="jumbotron kw-title">
                            {newsArchive ? (
                                <h5>{t(`${langFiles}.menu_news_archive`)}</h5>
                            ) : (
                                <h5>{t(`${langFiles}.menu_news`)}</h5>
                            )}
                            
                        </div>
                        {newsArchive ? (
                            data.news?.map(item => (
                                <NewsArchive key={item.newsId} article={item}  setSection={setSection} activeSection={section } />                           ))
                        ) : (
                            data.news?.map(item => (
                                <NewsDetails key={item.newsId} article={item}  setSection={setSection} activeSection={section}  />
                            ))
                        )}

                        <KwPagination setSection={setSection} maxRows={maxRows} totalRows={data.nbrOfRows} activeSection={section} />
                    </div>
                )
            )}
        </Fragment>
    );
}
 
export default withTranslation('common')(NewsListProvider);