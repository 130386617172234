import React, { useContext } from 'react';
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';

const LikeAndShare = ({linkToShare, showShare="true", buttonSize="large"}) => {
    const { activeTeam, isLoading, isError } = useContext(TeamContext);
    console.log('linkToShare = ', linkToShare);
    function Content() {
        return (
            activeTeam.page.facebook.appId > 0 ? (
                <div className="fb-like" 
                        data-href={ linkToShare }
                        data-width="" 
                        data-layout="button_count"
                        data-action="like" 
                        data-size={buttonSize} 
                        data-share={showShare}>
                </div>
            ) : (
                ''
            )
        )
    };
    
    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
            
        ) : (
            <Content />
        )
     );
}

export default LikeAndShare;