import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { TeamContext } from "../contexts/TeamContext";

const AppHeader = (props) => {
    const { t } = useTranslation();
    const { activeTeam } = useContext(TeamContext);
    const [fbScript, setFbScript] = useState('');
    const [iconURL, setIconURL] = useState('');
    const [logofileURL, setLogofileURL] = useState('');
    const [thisURL, setThisURL] = useState('');


    useEffect(() => {
        var full = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/';
        
        //Kan ikke sjekke på window på serveren når man bruker ssr. 
        //Window kan ikke sjekkes før koden kjører på klienten.
        setThisURL(window.location.href);

        if (typeof(activeTeam?.page.facebook.appId) !== "undefined" 
                && activeTeam?.page.facebook.appId !== null
                && activeTeam?.page.facebook.appId !== "") {
                    setFbScript("https://connect.facebook.net/" + 
                                t('language_abbreviation') +
                                "/sdk.js#xfbml=1&version=v7.0&appId=" + activeTeam?.page.facebook.appId + "&autoLogAppEvents=1");
        }; 
        if (typeof(activeTeam?.page.appicon) !== "undefined" 
                && activeTeam?.page.appicon !== null
                && activeTeam?.page.appicon !== "") {
                    setIconURL(full + activeTeam?.page.appicon);
        }; 
        if (typeof(activeTeam?.page.logofile) !== "undefined" 
                && activeTeam?.page.logofile !== null
                && activeTeam?.page.logofile !== "") {
                    setLogofileURL(full + activeTeam?.page.logofile);
        }; 
    }, [activeTeam, iconURL, logofileURL, t]);

    return (
        <Helmet>
            <meta charset="utf-8" /> y
            
            <link rel="icon" href={iconURL} />
            <link rel="apple-touch-icon" href={iconURL} />

            <title id="headTitle">{activeTeam?.team.name}</title>
            <meta name="description" content={activeTeam?.page.description} />

            {props.ogUrl ? (
                <meta property="og:url" content={props.ogUrl} />
            ) : (
                <meta property="og:url" content={thisURL} />
            )}
            {props.ogType ? (
                <meta property="og:type" content={props.ogType} />
            ) : (
                <meta property="og:type" content="website" />
            )}
            {props.ogTitle? (
                <meta property="og:title" content={props.ogTitle} />
            ) : (
                <meta property="og:title" content={activeTeam?.page.title} />
            )}
            {props.ogDescription? (
                <meta property="og:description" content={props.ogDescription} />
            ) : (
                <meta property="og:description" content={activeTeam?.page.description} />
            )}
            {props.ogImage? (
                <meta property="og:image" content={props.ogImage} />
            ) : (
                <meta property="og:image" content={logofileURL} />
            )}
            <script async 
                    defer 
                    crossorigin="anonymous" 
                    src={fbScript}
                    nonce="u60p7CNH">
            </script>
        </Helmet>
    );
}

export default AppHeader;