import React from "react";
import "./NotFound.css";
import { useTranslation, withTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-regular-svg-icons';

// export default function NotFound() {
const NotFound = () => {
    const langFiles = 'notFound';
    const { t } = useTranslation();
  return (
    <div className="NotFound">
      <h1><FontAwesomeIcon icon={faSadTear} /></h1>
      <h3>{t(`${langFiles}.page_not_found`)}</h3>
    </div>
  );
}

export default withTranslation('common')(NotFound);