import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';

/* const setOgValues = () => (
    alert("Skulle satt verdier");
); */

const LikeAndShare = ({showShare="true"}) => {
    const { activeTeam, isActiveTeamLoading, isActiveTeamError } = useContext(TeamContext);
    const { t } = useTranslation();
    
    function Content() {
        return (
            activeTeam?.page?.facebook?.appId > 0 ? (
                <Row className="justify-content-center pt-2">
                    {t('like_team_on_fb', { teamName: `${ activeTeam.team.name }` })}&nbsp;
                    <div className="fb-like" 
                         // data-href="https://facebook.com/vinpris.no" 
                         data-href={ activeTeam.page.facebook.appAddress }
                         data-width="" 
                         data-layout="button_count"
                         data-action="like" 
                         data-size="large" 
                         data-share={showShare}>
                    </div>
                </Row>
            ) : (
                ''
            )
        )
    };
    
    return ( 
        isActiveTeamLoading && !isActiveTeamError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
            
        ) : (
            <div className='kw-card'>
                <Content />
            </div>
        )
     );
}

export default LikeAndShare;