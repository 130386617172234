import { useState, useEffect, useReducer, useContext } from 'react';
import axios from 'axios';
import { TeamContext } from "../contexts/TeamContext";

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
          return {
               ...state,
               isLoading: true,
               isError: false, 
            };
        case 'FETCH_SUCCESS':
            return { 
                ...state,
                isLoading: false,
                isError: false, 
                data: action.payload,
          };
        case 'FETCH_FAILURE':
            return { ...state,
                isLoading: false,
                isError: true, 
            };
        default:
            throw new Error();
    }
};

const useDataApi = (initialUrl, initialParams) => {
    // debugger;
    const [url, setUrl] = useState(initialUrl);
    // let [defaultUrl, setDefaultUrl] = useState(initialUrl);
    let defaultUrl = initialUrl;
    let [params, setParams] = useState(initialParams);

    const { activeTeam } = useContext(TeamContext);
    let extraParams = "";

    if(params?.length) {
        extraParams = params.map(({name, value}) => `&${name}=${value}`).join('');
    } 

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: true,
        isError: false,
        // data: initialData,
    });

    // 1. Venter til aktivtLag er tilgjengelig.
    // useEffect(() => {
    //     const fetchData = async () => {
    //       const result = await thisTeam;
    //       setActiveTeam(result);
    //     };
    //     !isTeamLoading && (
    //         fetchData()
    //     )
    //   }, [isTeamLoading, thisTeam]);

    // 2. AkivtLag er tilgjengelig og jeg kan endre URL til riktig lag
    useEffect(() => {
        if(activeTeam?.team?.id) {
            const defaultParams = 
                '?teamId=' + activeTeam.team.id +
                '&clubId=' + activeTeam.club.id +
                '&showTeamsActivities=' + activeTeam.team.addons.showTeamsActivities +
                '&showClubSelfdefSharedMenu=' + activeTeam.team.addons.showClubSelfdefSharedMenu +
                '&showNewsOnFrontpage=' + activeTeam.team.addons.showNewsOnFrontpage +
                '&showClubNews=' + activeTeam.team.addons.showClubNews +
                '&showClubSelfdefPages=' + activeTeam.team.addons.showClubSelfdefPages +
                '&showClubSharedDocuments=' + activeTeam.team.addons.showClubSharedDocuments;
            setUrl(`${process.env.REACT_APP_HOST_API_URL}/${defaultUrl}${defaultParams}${extraParams}`);
        };

    }, [activeTeam, defaultUrl, extraParams, url]);
  
    // 3. URL har blitt satt og jeg kan hente dataene for laget
    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT'});
            try {
                const result = await axios(url);

                if (!didCancel) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data});
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: 'FETCH_FAILURE' });
                }
            }
        };
        if(url !== initialUrl) {
            //Denne useEffect blir kjørt når url blir satt første gang og 
            //da er ikke url ferdig ennå.
            fetchData();
        }

        // I mitt første forsøk måtte jeg teste at url hadde blitt satt. 
        // Eksempel på dette her sånn i tilfelle rottefelle
        // useEffect(() => {
        //     const fetchData = async () => {
        //         //doFetch(url);
        //         setIsError(false);
        //         setIsLoading(true);
        //         try {
        //             const result = await axios(url);
        //             setData(result.data);
        //         } catch (error) {
        //             setIsError(true);
        //         }
        //         setIsLoading(false);
        //     };
        //     if(Object.keys(url).length !== 0 && url.constructor !== Object) {
        //         console.log("Klar for å hente nyhetene");
        //         fetchData();
        //         // doFetch(url);
        //     }
        //   }, [url]);

        /**
         * Litt usikker på dette med didCancel. Har brukt det jeg fant i denne artikkelen :
         * https://www.robinwieruch.de/react-hooks-fetch-data
         * Det som returneres her er så vidt jeg skjønner cleanup-funksjonen som skal 
         * returneres fra useEffect.
         */
        

        return () => {
            didCancel = true;
        };
    }, [url, initialUrl]);

    return [state, setParams];
  }

  export default useDataApi;

// http://localhost:8084/webresources/news/getActiveNews?teamId=8&clubId=7&showTeamsActivities=true&showClubSelfdefSharedMenu=true&showNewsOnFrontpage=true&showClubNews=true&showClubSelfdefPages=true&showClubSharedDocuments=true
// http://localhost:8084/webresources/news/getActiveNews?teamId=8&clubId=7&showTeamsActivities=true&showClubSelfdefSharedMenu=true&showNewsOnFrontpage=true&showClubNews=true&showClubSelfdefPages=true&showClubSharedDocuments=true
// http://localhost:8084/webresources/news/getActiveNewsForTeamAndClub?teamId=8&clubId=7&showTeamsActivities=true&showClubSelfdefSharedMenu=true&showNewsOnFrontpage=true&showClubNews=true&showClubSelfdefPages=true&showClubSharedDocuments=true&maxRows=15&&showArcive=true