import React, { createContext, useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import useDataForTeamApi from '../api/DataForTeamApi';

export const PricecompareContext = createContext();

const PricecompareProvider = (props) => {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    const [selectedDisplayCountryName, setSelectedDisplayCountryName] = useState(t(`${langFiles}.All_countries`));
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [selectedDisplayProductType, setSelectedDisplayProductType] = useState(t(`${langFiles}.productTypes.All_product_types`));
    const [selectedProductType, setSelectedProductType] = useState("");
    const [selectedDisplayComparisation, setSelectedDisplayComparisation] = useState(t(`${langFiles}.comparison.CheapestInNorway_inPercent`));
    const [selectedComparisation, setSelectedComparisation] = useState('B-NO-PRO');
    const [selectedDisplayVolume, setSelectedDisplayVolume] = useState(t(`${langFiles}.All_volumes`));
    const [selectedVolume, setSelectedVolume] = useState("-");
    const [sortingRadioValue, setSortingRadioValue] = useState('DIFF');
    const [productSearchValue, setProductSearchValue] = useState('');
    const [productSearchDisplayValue, setProductSearchDisplayValue] = useState(productSearchValue);

    const [{ data, isLoading, isError }, changeParams] = useDataForTeamApi(
        'wineprice/getProducts',
        []
    );

    const [{ data: countryData, isLoading: isCountryLoading, isError: isCountryError }] = useDataForTeamApi(
        'wineprice/getAllCountries',
        []
    );

    const [{ data: productTypeData, isLoading: isProductTypeLoading, isError: isProductTypeError }, changeProductTypeParams] = useDataForTeamApi(
        'wineprice/getAllProductTypesGrouped',
        []
    );

    const [{ data: volumeData, isLoading: isVolumeLoading, isError: isVolumeError }, changeVolumeParams] = useDataForTeamApi(
        'wineprice/getAllVolumes',
        []
    );

    const [{ data: currencyData, isLoading: isCurrencyLoading, isError: isCurrencyError }] = useDataForTeamApi(
        'wineprice/getCurrency',
        []
    );

    const changeData = useCallback((newParams) => {
        changeParams(newParams);
    }, [changeParams]);


    const changeProductTypeData = useCallback((newParams) => {
        changeProductTypeParams(newParams);

    }, [changeProductTypeParams]);

    const changeVolumeData = useCallback((newParams) => {
        changeVolumeParams(newParams);

    }, [changeVolumeParams]);


    return (
        <PricecompareContext.Provider 
            value={{data, isLoading, isError, changeData, 
                countryData, isCountryLoading, isCountryError, selectedCountryName, setSelectedCountryName, selectedDisplayCountryName, setSelectedDisplayCountryName,
                volumeData, isVolumeLoading, isVolumeError, changeVolumeData, selectedVolume, setSelectedVolume, selectedDisplayVolume, setSelectedDisplayVolume,
                currencyData, isCurrencyLoading, isCurrencyError,
                productTypeData, isProductTypeLoading, isProductTypeError, changeProductTypeData, selectedProductType, setSelectedProductType, selectedDisplayProductType, setSelectedDisplayProductType,
                selectedComparisation, setSelectedComparisation, selectedDisplayComparisation, setSelectedDisplayComparisation,
                sortingRadioValue, setSortingRadioValue,
                productSearchValue, setProductSearchValue, productSearchDisplayValue, setProductSearchDisplayValue
                }
            }>
            {props.children}
        </PricecompareContext.Provider>
    )
}


export default PricecompareProvider;
