import React, {useState, useEffect} from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function VolumeSelector({volumeData, isVolumeError, selectedDisplayVolume, setSelectedDisplayVolume, selectedVolume, setSelectedVolume, setSection}) {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    const [variantClass, setVariantClass] = useState('danger');
    const [variantClassFinished, setVariantClassFinished] = useState(false);


    const handleVolume=(e)=>{
        const delimiterPos = e.indexOf("|");
        const selectedVolume = e.substring(0, delimiterPos);

        setSelectedVolume(selectedVolume);

        selectedVolume.localeCompare("-") !== 0 ? (
            setSelectedDisplayVolume(e.substring(delimiterPos + 1) + " cl")
        ) : (
            setSelectedDisplayVolume(e.substring(delimiterPos + 1))
        );
        setSection(1);
    }

    useEffect(() => {
        setVariantClass('danger');
        
        const checkIfSelectedExists = () => {
            let checkName = '';
            if (volumeData) {
                // Hvis Alle produkttyper er valgt vil produktet helt sikkert finnes
                if(selectedVolume.localeCompare("-") === 0) {
                    setVariantClass('light');
                    setVariantClassFinished(true);
                };
                //Hvis ikke Alle volum var valgt går jeg gjennom lista med produkttyper 
                //og sjekker om det som er valgt finnes der.
                volumeData.volumes.map((item) => {
                    checkName = item.volume;
                    if(selectedVolume.localeCompare(checkName) === 0) {
                        setVariantClass('light');
                        setVariantClassFinished(true);
                    };
                    return true;
                });
            }

            setVariantClassFinished(true);
            return true;
        };
        
        if(volumeData) {
            checkIfSelectedExists();
        }
    }, [volumeData, selectedVolume, t]);
    
    return (
        isVolumeError ? (
            <div>
                {t('could_not_retrieve_data')}
            </div>
        )   : (
            !variantClassFinished ? (
                <div>
                    <Spinner animation="border" variant="secondary" size="sm" /> {t(`${langFiles}.Retrieving_volume`)}
                </div>
            ) : (
                <div>
                    <DropdownButton
                        variant={variantClass}
                        alignRight
                        title={selectedDisplayVolume}
                        onSelect={handleVolume}
                        >
                        <Dropdown.Item key={`vol_All_volumes`} eventKey={"-|" + t(`${langFiles}.All_volumes`)}>{t(`${langFiles}.All_volumes`)}</Dropdown.Item>
                        {volumeData?.volumes?.map(item => (
                            <Dropdown.Item key={`vol_${item.volume}`} eventKey={`${item.volume}|${item.volume}`}>{item.volume + " " + t(`${langFiles}.titles.cl`)}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>
            )
        )
    )
}

export default withTranslation('common')(VolumeSelector);
