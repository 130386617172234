import React, { useContext } from 'react';
import ArticleText from '../../atomic/atoms/ArticleText';
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';

const FirstpageContent = ({ article, forceShowText }, context) => {
    const { activeTeam, isLoading, isError } = useContext(TeamContext);

    function Content() {
        return (
            <div className={`card mt-1 ${activeTeam.page.design.stylesheet}`}>
                <div className="card-body">
                    <div className="card-content grey-text text-darken-3">
                        <h1 className="card-title">{activeTeam.frontpage.title}</h1>

                        <ArticleText
                            teamId = { activeTeam.team.id }
                            text = { activeTeam.frontpage.text }
                        />
                    </div>
                </div>
            </div>
        )
    };
    
    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
            
        ) : (
            <div className='kw-card'>
                <Content />
            </div>
        )
     );
}

export default FirstpageContent;