import React, { createContext } from "react";
import useDataApi from '../api/DataForTeamApi'

/* Denne filen er lagd etter oppskriften her:
 * https://www.codementor.io/@sambhavgore/an-example-use-context-and-hooks-to-share-state-between-different-components-sgop6lnrd
 * og kraftig etterjustert etter denne: https://www.youtube.com/watch?v=CGRpfIUURE0&list=PL4cUxeGkcC9hNokByJilPg5g9m2APUePI&index=3
 */

export const DataContext = createContext();

const DataContextProvider = props => {
  const {
    children
  } = props;

  let nyservice = '';
  let nyeParams = '';

  const setHeleDritten = (service, params) => {
    nyservice = service;
    nyeParams = params;
  }

  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    `${nyservice}`,
    { nyeParams },
  );

  return (
    <DataContext.Provider value={{data, isLoading, isError, doFetch, setHeleDritten}}>
      {children}
    </DataContext.Provider>
  );
}

export default DataContextProvider;