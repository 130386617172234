import React, {useState, useEffect} from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function ProductTypeSelector({productTypeData, 
                              isProductTypeError,
                              setSelectedDisplayProductType,
                              selectedDisplayProductType,
                              setSelectedProductType,
                              selectedProductType, 
                              setSection}) {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();
    let keyCounter=0;

    const [variantClass, setVariantClass] = useState('danger');
    const [variantClassFinished, setVariantClassFinished] = useState(false);

    const handleProductType=(e)=>{
        const delimiterPos = e.indexOf("|");
        if(delimiterPos === 0) {
            setSelectedProductType("");
        } else if (delimiterPos > 0) {
            setSelectedProductType(e.substring(0, delimiterPos));
        } else {
            setSelectedProductType(e);
        }
        
        setSelectedDisplayProductType(t(`${langFiles}.productTypes.${e.substring(delimiterPos + 1)}`));
        setSection(1);
        
    }

    useEffect(() => {
        setVariantClass('danger');
        
        const checkIfSelectedExists = () => {
            let checkName = '';
            if (productTypeData) {
                // Hvis Alle produkttyper er valgt vil produktet helt sikkert finnes
                if(t(`${langFiles}.productTypes.All_product_types`).localeCompare(selectedDisplayProductType) === 0) {
                    setVariantClass('light');
                    setVariantClassFinished(true);
                };
                //Hvis ikke Alle produkttyper var valgt går jeg gjennom lista med produkttyper 
                //for det valgte landet og sjekker om det som er valgt finnes der.
                productTypeData.productTypes.map((item) => {
                    const delimiterPos = item.selectedName.indexOf("|");
                    if (delimiterPos > 0) {
                        checkName = item.selectedName.substring(0, delimiterPos);
                    } else {
                        checkName = item.selectedName;
                    }
                    if(checkName.localeCompare(selectedProductType) === 0 || item.typeName.localeCompare(selectedProductType) === 0) {
                        setVariantClass('light');
                        setVariantClassFinished(true);
                    };
                    return true;
                });
            }

            setVariantClassFinished(true);
            return true;
        };
        
        if(productTypeData) {
            checkIfSelectedExists();
        }
    }, [productTypeData, selectedDisplayProductType, selectedProductType, t]);
    
    return (
        isProductTypeError ? (
            <div>
                {t('could_not_retrieve_data')}
            </div>
        )   : (
            !variantClassFinished ? (
                <div>
                    <Spinner animation="border" variant="secondary" size="sm" /> {t(`${langFiles}.Retrieving_product_type`)}
                </div>
            ) : (
                <div>
                    <DropdownButton
                        variant={variantClass}
                        alignRight
                        title={selectedDisplayProductType}
                        onSelect={handleProductType}
                        >
                        <Dropdown.Item key={`id_All_product_types`} className="level-1" eventKey={"|All_product_types"}>{t(`${langFiles}.productTypes.All_product_types`)}</Dropdown.Item>
                        {productTypeData?.productTypes?.map(item => (
                            <Dropdown.Item key={`id_${keyCounter++}_${item.selectedName}`} className={"level-" + item.level} eventKey={item.selectedName}>{t(`${langFiles}.productTypes.${item.displayName}`)}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>
            )
        )
    )
}

export default withTranslation('common')(ProductTypeSelector);
