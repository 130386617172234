import React from 'react';
import PropTypes from 'prop-types';
// import { config } from '../../Constants';
import Image from 'react-bootstrap/Image';
// var apiUrl = config.url.API_URL + '/no.klubbweb.ImageServlet';
var apiUrl = `${process.env.REACT_APP_DOMAIN}/no.klubbweb.ImageServlet`;


/**
 * bildeId: Må være med hvis det hentes bilder fra klubbweb
 * src: Path til eventuell lokal fil.
 * alt: Alternativ tekst
 * classes: kan være react-klassene "rounded", "rounded-circle" og "img-fluid"
 * ellers kan egne klasenavn også sendes med
 *
 */


export const imagePropType = PropTypes.shape({
    bildeId: PropTypes.number,
    src: PropTypes.string,
    alt: PropTypes.string,
    classes: PropTypes.string,
    showImage: PropTypes.bool,
}).isRequired;

const Kw_Image = ({
    bildeId,
    src,
    alt,
    classes,
    showImage = true,
    isThumbnail = false,
  ...rest
}) => {

const sm = 400;
const md = 600;
const lg = 800;
const xl = 1200;
const thmb = 300;
var reduser = 0;
// debugger;
const ImageComponent = 'img';
  if (isThumbnail) {
    reduser = thmb;
  } 
  if(showImage) {
    if (!src) {
      // No src so it must be an KlubbwebImage
      if (bildeId && bildeId > 0) {
        if (!isThumbnail) {
          return(
            <Image
              src={`${apiUrl}?FILEID=${bildeId}` } sizes={`(max-width: ${sm - reduser}px) ${sm - reduser}px, 
              (min-width: ${sm - reduser}px) and (max-width: ${md - reduser}px) ${md - reduser}px, 
              (min-width: ${md - reduser}px) and (max-width: ${lg - reduser}px) ${lg - reduser}px, 
              ${xl}px`}
              srcSet={`${apiUrl}?FILEID=${bildeId}&amp;maxsize=${sm - reduser}  ${sm - reduser}w,
                        ${apiUrl}?FILEID=${bildeId}&amp;maxsize=${md - reduser}  ${md - reduser}w,
                        ${apiUrl}?FILEID=${bildeId}&amp;maxsize=${lg - reduser}  ${lg - reduser}w,
                        ${apiUrl}?FILEID=${bildeId} ${xl - reduser}w`}
              alt={alt}
              className= {`${classes}` }
              {...rest}
            />
          );
        } else {
          return(
            <Image
              src={`${apiUrl}?FILEID=${bildeId}` } sizes={`(max-width: ${sm - reduser}px) ${sm - reduser}px, 
              (min-width: ${sm - reduser}px) and (max-width: ${md - reduser}px) ${md - reduser}px, 
              (min-width: ${md - reduser}px) and (max-width: ${lg - reduser}px) ${lg - reduser}px, 
              ${xl}px`}
              srcSet={`${apiUrl}?FILEID=${bildeId}&amp;maxsize=${sm - reduser}  ${sm - reduser}w,
                        ${apiUrl}?FILEID=${bildeId}&amp;maxsize=${md - reduser}  ${md - reduser}w,
                        ${apiUrl}?FILEID=${bildeId}&amp;maxsize=${lg - reduser}  ${lg - reduser}w,
                        ${apiUrl}?FILEID=${bildeId} ${xl - reduser}w`}
              alt={alt}
              className= {`${classes}` }
              height='100'
              {...rest}
            />
          );

        }
      } else {
          return '';
      }
    } else {
      return <ImageComponent
          src={src}
          alt={alt}
          className= {`${classes}` }
        {...rest}
      />;
    }
  } else {
    return '';
  }
};

Image.propTypes = imagePropType;

export default Kw_Image;
