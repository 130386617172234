import React, { createContext, useCallback } from 'react';
import useDataForTeamApi from '../api/DataForTeamApi';

export const SelfdeflistContext = createContext();

const SelfdeflistContextProvider = (props) => {

    const [{ data, isLoading, isError }, changeParams] = useDataForTeamApi(
        'selfdefpages/getSelfdefPagesForCollection',
        []
    );

    const changeData = useCallback((newParams) => {
        changeParams(newParams);
    }, [changeParams]);

    return (
        <SelfdeflistContext.Provider value={{data, isLoading, isError, changeData}}>
            {props.children}
        </SelfdeflistContext.Provider>
    )
}


export default SelfdeflistContextProvider;
