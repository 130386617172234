import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function ComparisationSelector({setSelectedComparisation, 
                                selectedDisplayComparisation, 
                                setSelectedDisplayComparisation, 
                                setSection}) {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    const handleComparisation=(e)=>{
        const delimiterPos = e.indexOf("|");

        setSelectedComparisation(e.substring(0, delimiterPos));
        setSelectedDisplayComparisation(e.substring(delimiterPos + 1));
        setSection(1);
    }
    
    return (
        <DropdownButton
            variant="light"
            alignRight
            title={selectedDisplayComparisation}
            onSelect={handleComparisation}
            >
            <Dropdown.Item key="B-NO-PRO" eventKey={"B-NO-PRO|" + t(`${langFiles}.comparison.CheapestInNorway_inPercent`)}>{t(`${langFiles}.comparison.CheapestInNorway_inPercent`)}</Dropdown.Item>
            <Dropdown.Item key="B-SE-PRO" eventKey={"B-SE-PRO|" + t(`${langFiles}.comparison.CheapestInSweden_inPercent`)}>{t(`${langFiles}.comparison.CheapestInSweden_inPercent`)}</Dropdown.Item>
            <Dropdown.Item key="B-NO-LTR" eventKey={"B-NO-LTR|" + t(`${langFiles}.comparison.CheapestInNorway_PrLitre`)}>{t(`${langFiles}.comparison.CheapestInNorway_PrLitre`)}</Dropdown.Item>
            <Dropdown.Item key="B-SE-LTR" eventKey={"B-SE-LTR|" + t(`${langFiles}.comparison.CheapestInSweden_PrLitre`)}>{t(`${langFiles}.comparison.CheapestInSweden_PrLitre`)}</Dropdown.Item>
            <Dropdown.Item key="B-NO-VOL" eventKey={"B-NO-VOL|" + t(`${langFiles}.comparison.CheapestInNorway_PrVolume`)}>{t(`${langFiles}.comparison.CheapestInNorway_PrVolume`)}</Dropdown.Item>
            <Dropdown.Item key="B-SE-VOL" eventKey={"B-SE-VOL|" + t(`${langFiles}.comparison.CheapestInSweden_PrVolume`)}>{t(`${langFiles}.comparison.CheapestInSweden_PrVolume`)}</Dropdown.Item>
        </DropdownButton>
    )
}

export default withTranslation('common')(ComparisationSelector);
