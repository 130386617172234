import React, { Fragment, useContext, useEffect} from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import NewsDetails from '../news/NewsDetails';
import Spinner from 'react-bootstrap/Spinner';
import { NewslistContext } from '../news/NewslistContext';
import { PaginationContext } from '../contexts/PaginationContext';
import KwPagination from '../../atomic/organisms/KwPagination';
import { TeamContext } from "../contexts/TeamContext";
import FirstpageContent from './FirstpageContent';

const FirstpageListProvider = (props) => {
    const langFiles = 'navbar';
    const { t } = useTranslation();
    const { activeTeam } = useContext(TeamContext);

    const { data, isLoading, isError, changeData } = useContext(NewslistContext);
    const { section, setSection } = useContext(PaginationContext);
    const maxRows = 5;
    !section && setSection(1);
    const showNewsOnFrontpage = activeTeam?.team.addons.showNewsOnFrontpage;

    useEffect(() => {
        changeData(
            [
                {
                    name: 'maxRows',
                    value: maxRows
                },
                {
                    name: 'section',
                    value: section
                }
            ]
        );
    }, [changeData, section]);

    return (
        <Fragment>
            {activeTeam && (
                <FirstpageContent />
            )}
            {showNewsOnFrontpage && (
                isError ? (
                    <div>
                        {t('could_not_retrieve_data')}
                    </div>
                )   : (
                    isLoading && !isError ? (
                        <div>
                            <Spinner animation="border" variant="secondary" size="sm" /> {t('retrieving_news')}
                        </div>
                    ) : (
                        <div>
                            <div className="jumbotron kw-title">
                                <h5>{t(`${langFiles}.menu_news`)}</h5>
                                
                            </div>
                            {data.news?.map(item => (
                                <NewsDetails key={item.newsId} article={item}  setSection={setSection} activeSection={section}/>
                            ))}

                            <KwPagination setSection={setSection} maxRows={maxRows} totalRows={data.nbrOfRows} activeSection={section} />
                        </div>
                    )
                )
            )}
        </Fragment>
    );
}
 
export default withTranslation('common')(FirstpageListProvider);