import React, { Fragment, useContext, useEffect} from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import SelfdefDetails from './SelfdefDetails';
import SelfdefLink from './SelfdefLink';
import Spinner from 'react-bootstrap/Spinner';
import { SelfdeflistContext } from './SelfdeflistContext';
import { PaginationContext } from '../contexts/PaginationContext';
import KwPagination from '../../atomic/organisms/KwPagination';

const SelfdefListProvider = (props) => {
    const { t } = useTranslation();

    const { data, isLoading, isError, changeData } = useContext(SelfdeflistContext);
    const { section, setSection } = useContext(PaginationContext);
    const maxRows = 5;
    !section && setSection(1);

    useEffect(() => {
        changeData(
            [
                {
                    name: "collectionId",
                    value: props.match.params.collectionId
                },
                {
                    name: 'maxRows',
                    value: maxRows
                },
                {
                    name: 'section',
                    value: section
                }
            ]
        );
    }, [changeData, props.match.params.collectionId, section]);

    return (
        <Fragment>
            {isError ? (
                <div>
                    {t('could_not_retrieve_content')}
                </div>
            )   : (
                isLoading && !isError ? (
                    <div>
                        <Spinner animation="border" variant="secondary" size="sm" /> {t('retrieving_content')}
                    </div>
                ) : (
                    <div>
                        {data.collection?.presentation === 1 ? (
                            data.pages?.map(item => (
                                <SelfdefDetails key={item.pageId} article={item}  setSection={setSection} activeSection={section}/>
                            ))
                        ) : (
                            data.pages?.map(item => (
                                <SelfdefLink key={item.pageId} article={item}  setSection={setSection} activeSection={section}/>
                            ))
                        )}
                        

                        <KwPagination setSection={setSection} maxRows={maxRows} totalRows={data.nbrOfRows} activeSection={section} />
                    </div>
                )
            )}
        </Fragment>
    );
}
 
export default withTranslation('common')(SelfdefListProvider);