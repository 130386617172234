import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { NavDropdown } from 'react-bootstrap';
const SubTeamsSelector = (props) => {
    const { t } = useTranslation();
    const langFiles = 'teamMenu';
    let lastClubType = 0;
    let lastClubCategory = 0;
    let teamInset = "";
    let typeInset = "";
    let nbrOfSubteams = props.subTeams.length;
    let sisteKategori=0, sisteType=0, antallKategorier=0, antallKlubbtyper=0;

    for( let i = 0; i < nbrOfSubteams; i ++) {
        if(props.subTeams[i].club.categoryId !== sisteKategori) {
            sisteKategori=props.subTeams[i].club.categoryId ;
            antallKategorier++;
        };
        if(props.subTeams[i].club.typeId !== sisteType) {
            sisteType = props.subTeams[i].club.typeId;
            antallKlubbtyper++;
        };
    }

    if(antallKategorier > 1) {
        typeInset = "\u00A0\u00A0";
        teamInset = "\u00A0\u00A0\u00A0\u00A0";
    }
    console.log(props.activeTeam.page.design.ourTeamsText);

    return (
        antallKategorier > 0 && (
            <NavDropdown title={t(`${langFiles}.${props.activeTeam.page.design.ourTeamsText}`)} id="collasible-nav-dropdown">
                {props.subTeams.map(function(item, i) {
                    if(item.club.categoryId !== lastClubCategory && antallKategorier > 1 ) {
                        lastClubCategory = item.club.categoryId;
                        return(
                            <div key={`menugroup_${i}`}>
                                <NavDropdown.Item disabled key={`c-${item.club.id}`}>
                                {item.club.categoryName}
                                </NavDropdown.Item>
                                {item.club.typeId === lastClubType ? (
                                    <NavDropdown.Item key={`t-${item.team.id}`} href={"/?lag=" + item.team.id}>
                                        {`${teamInset} ${item.team.name}`}
                                    </NavDropdown.Item>
                                ) : (
                                    lastClubType = item.club.typeId,
                                    <>
                                    <NavDropdown.Item disabled key={`c-${item.club.id}`}>
                                        {typeInset}{item.club.typeName}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item key={`t-${item.team.id}`} href={"/?lag=" + item.team.id}>
                                        {`${teamInset} ${item.team.name}`}
                                    </NavDropdown.Item>
                                    </>
                                )}
                            </div>
                        )
                    } 
                    if (item.club.typeId !== lastClubType && antallKlubbtyper > 1) {
                        lastClubType = item.club.typeId;
                        return(
                            <div key={`menugroup_${i}`}>
                                <NavDropdown.Item disabled key={`c-${item.club.id}`}>
                                    {item.club.typeName}
                                </NavDropdown.Item>
                                <NavDropdown.Item key={`t-${item.team.id}`} href={"/?lag=" + item.team.id}>
                                    {`${teamInset} ${item.team.name}`}
                                </NavDropdown.Item>
                            </div>
                        )
                    }
                    return(
                        <div key={`menugroup_${i}`}>
                            <NavDropdown.Item off="true" key={`t-${item.team.id}`} href={"/?lag=" + item.team.id}>
                                {`${teamInset}  ${item.team.name}`}
                            </NavDropdown.Item>
                        </div>
                    )
                })
                }
            </NavDropdown>
        )
    );
}

export default withTranslation('common')(SubTeamsSelector);