import React, { Fragment, useContext } from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { TeamContext } from "../contexts/TeamContext";
import SubTeamsSelector from './SubTeamsSelector';

const TeamMenu = () => {
 
    const { activeTeam, subTeams } = useContext(TeamContext);

    return (
        <Fragment>
            {subTeams?.length >0 && (
                activeTeam.team.isClubMainPage ? (
                    <div className={`${activeTeam.page.design.stylesheet} team-menu`}>
                        <Breadcrumb>
                            <Breadcrumb.Item key="1" href={"/?lag=" + activeTeam.club.id}>{activeTeam.team.name}</Breadcrumb.Item>
                            <SubTeamsSelector key="2" activeTeam={activeTeam} subTeams={subTeams} />
                        </Breadcrumb>
                    </div>
                ) : (
                    <div className={`${activeTeam.page.design.stylesheet} team-menu`}>
                        <Breadcrumb>
                            <Breadcrumb.Item key="1" href={"/?lag=" + activeTeam.club.id}>{activeTeam.club.name}</Breadcrumb.Item>
                            <SubTeamsSelector key="2" activeTeam={activeTeam} subTeams={subTeams} />
                            <Breadcrumb.Item key="3" href={"?lag=" + activeTeam.team.id}>{activeTeam.team.name}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                )
            )}
        </Fragment>
    );
}

export default TeamMenu;