import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser } from '@fortawesome/free-solid-svg-icons';

function ProductSearch({productSearchValue, setProductSearchValue, productSearchDisplayValue, setProductSearchDisplayValue, setSection}) {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();


    const handleChange = (e) => {
        setProductSearchDisplayValue(e.target.value);
    }

    const clearSearch = () => {
        setProductSearchValue('');
        setProductSearchDisplayValue('');
    }

    const searchProducts = () => {
        setProductSearchValue(document.getElementById('searchInput').value);
        setSection(1);
    }

    return (
        <>
        <InputGroup>
            <FormControl
                placeholder={t(`${langFiles}.Search_for_name_or_number`)} 
                aria-label={t(`${langFiles}.Search_for_name_or_number`)} 
                aria-describedby="basic-addon2"
                id='searchInput'
                onChange={handleChange}
                value={productSearchDisplayValue}
            />
            <InputGroup.Append>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch}  onClick={searchProducts}/>
                </InputGroup.Text>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faEraser} onClick={clearSearch} />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
        </>
    );
}

export default withTranslation('common')(ProductSearch);
