import React, { Fragment, useContext } from 'react';
import useDataForTeamApi from '../api/DataForTeamApi';
import SelfdefDetails from './SelfdefDetails';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import { TeamContext } from "../contexts/TeamContext";

const SelfdefOneProvider = (props) => {
    const { activeTeam } = useContext(TeamContext);
    const { t } = useTranslation();
    const history = useHistory();
    
    const [{ data, isLoading, isError }] = useDataForTeamApi(
        'selfdefpages/getOneSelfdefPage',
        [
            {
                name: "pageId",
                value: props.match.params.pageId
            },
        ]
    );

    const goback = (e) => {
        history.goBack();
    };


    return (
        <Fragment>
            {isError && <div>{t('could_not_retrieve_data')}</div>}
            {isLoading && !isError ? (
                <div>
                    <Spinner animation="border" variant="secondary" size="sm" />
                    {t('retrieving_article')}
                </div>
                
            ) : (
                <div className={activeTeam.page.design.stylesheet}>
                    {data.pages?.map(item => (
                        <SelfdefDetails key={item.pageId} article={item} forceShowText/>
                    ))}
                    <Row className="justify-content-center">
                        <div className='kw-backbutton' onClick={goback}>
                            <p><FontAwesomeIcon icon={faArrowLeft} /> {t('history_back')}</p>
                        </div>
                    </Row>
                </div>
            )}
        </Fragment>
    );
}
 
export default withTranslation('common')(SelfdefOneProvider);