import React, { Fragment, useContext, useState, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import useDataApi from '../api/DataApi';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { TeamContext } from "../contexts/TeamContext";
import { PaginationContext } from "../contexts/PaginationContext";
import Spinner from 'react-bootstrap/Spinner';

const NavbarPage = (props) => {
    const langFiles = 'navbar';
    const { t, i18n } = useTranslation();
    
    const { activeTeam, teamId } = useContext(TeamContext);
    const { setSection } = useContext(PaginationContext);

    const [{ data, isLoading, isError }] = useDataApi(
        `mainMenu/getMainMenu?teamId=${teamId}`,
        { mainMenu: [] },
    );

    const [logoURL, setLogoURL] = useState('');
    const [logoTitle, setLogoTitle] = useState('');

    useEffect(() => {
        if (typeof(activeTeam?.page.logofile) !== "undefined" 
                && activeTeam?.page.logofile !== null
                && activeTeam?.page.logofile !== "") {
            setLogoURL("./../logofiles/" + activeTeam?.page?.logofile);
        }; 
    }, [activeTeam]);

    useEffect(() => {
        if (typeof(activeTeam?.page.title) !== "undefined" 
                && activeTeam?.page.title !== null
                && activeTeam?.page.title !== "") {
            setLogoTitle(activeTeam?.page.title);
        } else {
            setLogoTitle(activeTeam?.team.name);
        }; 
    }, [activeTeam]);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        window.location.reload();
    };

    return (
        <Fragment>
            {isError ? (
               <div>{t('could_not_retrieve_menu')}</div>
            ) : (
                // isLoading || isTeamLoading || !thisTeam || !thisTeam.page || !thisTeam.page.design || !thisTeam.page.design.stylesheet ? (
                // isLoading || isTeamLoading || !thisTeam?.page?.design?.stylesheet ? (
                isLoading || !activeTeam?.page?.design?.stylesheet ? (
                    <div>
                        <Spinner animation="border" variant="secondary" size="sm" /> {t('retrieving_menu')}
                    </div>
                ) : (
                    <>
                    {/* <div className={thisTeam.page.design.stylesheet}>
                        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
                        <Navbar.Brand href={"/?lag=" + thisTeam.team.id}>{thisTeam.team.name}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="#features">Features</Nav.Link>
                                {data.menuContent.map(item => (
                                    <Nav.Link href={`/${item.command}?lag=${thisTeam.team.id}`} key={item.menuId} as={Link} to={`/${item.command}?lag=${thisTeam.team.id}`}>{t(`${langFiles}.${item.text.replace(/[.]/g, '_')}`)}</Nav.Link>
                                ))}
                                <Nav.Link href="#pricing">Pricing</Nav.Link>
                                <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                                </NavDropdown>
                                </Nav>
                                <Nav>
                                <NavDropdown title={t(`${langFiles}.language`)} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="#no" onClick={() => i18n.changeLanguage('no')}>{t(`${langFiles}.languages.norwegian.name`)}</NavDropdown.Item>
                                    <NavDropdown.Item href="#se" onClick={() => i18n.changeLanguage('se')}>{t(`${langFiles}.languages.swedish.name`)}</NavDropdown.Item>
                                    <NavDropdown.Item href="#en" onClick={() => i18n.changeLanguage('en')}>{t(`${langFiles}.languages.english.name`)}</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                        </Navbar>
                    </div> */}
                    <div className={activeTeam.page.design.stylesheet}>
                        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
                            <Navbar.Brand justify-content-center="true" href={"/?lag=" + activeTeam.team.id}>
                                {logoURL !== "" ? (
                                    <img
                                        src={logoURL}  
                                        width="100"
                                        height="100"
                                        className="d-inline-block align-top"
                                        alt={logoTitle}
                                    />
                                ) : (
                                    logoTitle 
                                )}
{/*                                 <div className="d-inline-block align-bottom">
                                    {logoTitle}
                                </div> */}
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                    {activeTeam?.club.categoryId === 2 &&
                                        <Nav.Link key={`mm_price_compare`} href={`/price_compare?lag=${activeTeam.team.id}`} as={Link} to={`/price_compare?lag=${activeTeam.team.id}`}>{t(`heading.price_comparison_norway_sweeden`)}</Nav.Link>
                                    }
                                    {data.mainMenu.map(item => (
                                        item.text === "menu.news" ? (
                                            <NavDropdown key={item.menuId} title={t(`${langFiles}.${item.text.replace(/[.]/g, '_')}`)} id="news-nav-dropdown">
                                                <NavDropdown.Item onClick={() => setSection(1)} href="#ny" as={Link} to={`/${item.command}?lag=${activeTeam.team.id}&arc=0`}>{t(`${langFiles}.${item.text.replace(/[.]/g, '_')}`)}</NavDropdown.Item>
                                                <NavDropdown.Item onClick={() => setSection(1)} href="#na" as={Link} to={`/${item.command}?lag=${activeTeam.team.id}&arc=1`}>{t(`${langFiles}.menu_news_archive`)}</NavDropdown.Item>
                                                {/* <NavDropdown.Item href="#na" as={Link} to={`/${item.command}_archive?lag=${activeTeam.team.id}`}>{t(`${langFiles}.menu_news_archive`)}</NavDropdown.Item> */}
                                            </NavDropdown>
                                            // <Nav.Link key={item.menuId} href={`/${item.command}?lag=${activeTeam.team.id}`} as={Link} to={`/${item.command}?lag=${activeTeam.team.id}`}>{t(`${langFiles}.${item.text.replace(/[.]/g, '_')}`)}</Nav.Link>
                                        ) : (
                                            <Nav.Link key={`mm_${item.menuId}`} href={`/${item.command}?lag=${activeTeam.team.id}`} as={Link} to={`/${item.command}?lag=${activeTeam.team.id}`}>{t(`${langFiles}.${item.text.replace(/[.]/g, '_')}`)}</Nav.Link>
                                        )
                                    ))}
                                    {data.sefdefPageMenuList.map(item => (
                                        <Nav.Link key={`sdp_${item.collectionId}`} onClick={() => setSection(1)} href={`/${item.collectionName.replace(/[ ]/g, '_')}?lag=${activeTeam.team.id}`} as={Link} to={`/list_selfdefpages/${item.collectionId}?lag=${activeTeam.team.id}`}>{item.collectionName}</Nav.Link>
                                    ))}
                                </Nav>
                                <Nav>
                                   <NavDropdown title={
                                            <div style={{display: "inline-block"}}>
                                                <img className="pr-1" 
                                                    src="./../flags/norway-flag-square-icon-16.png" 
                                                    alt={t(`${langFiles}.languages.norwegian.name`)}
                                                />
                                                <img className="pr-1" 
                                                    src="./../flags/sweden-flag-square-icon-16.png" 
                                                    alt={t(`${langFiles}.languages.swedish.name`)}
                                                />
                                                <img className="pr-1" 
                                                    src="./../flags/united-kingdom-flag-square-icon-16.png" 
                                                    alt={t(`${langFiles}.languages.english.name`)}
                                                />
                                                {/* {t(`${langFiles}.language`)} */}
                                            </div>
                                        } 
                                        alignRight 
                                        id="collasible-nav-dropdown">
                                        <NavDropdown.Item href="#no" onClick={() => changeLanguage('no')}>
                                            <img className="thumbnail-image pr-2" 
                                                src="./../flags/norway-flag-square-icon-32.png" 
                                                alt={t(`${langFiles}.languages.norwegian.name`)}
                                            />
                                            {t(`${langFiles}.languages.norwegian.name`)}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="#se" onClick={() => changeLanguage('se')}>
                                            <img className="thumbnail-image pr-2" 
                                                src="./../flags/sweden-flag-square-icon-32.png" 
                                                alt={t(`${langFiles}.languages.swedish.name`)}
                                            />
                                            {t(`${langFiles}.languages.swedish.name`)}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="#en" onClick={() => changeLanguage('en')}>
                                            <img className="thumbnail-image pr-2" 
                                                src="./../flags/united-kingdom-flag-square-icon-32.png" 
                                                alt={t(`${langFiles}.languages.english.name`)}
                                            />
                                            {t(`${langFiles}.languages.english.name`)}
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>

                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    </>
                )
            )}
        </Fragment>
    );
}

export default withTranslation('common')(NavbarPage);