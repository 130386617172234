import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function CountrySelector({countryData, isCountryLoading, isCountryError, setSelectedCountryName, selectedDisplayCountryName, setSelectedDisplayCountryName, setSection}) {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    const handleCountry=(e)=>{
        if(e===t(`${langFiles}.All_countries`)) {
            setSelectedCountryName('');
        } else {
            setSelectedCountryName(e);
        }
        
        setSelectedDisplayCountryName(e);
        setSection(1)
    }
    
    return (
        isCountryError ? (
            <div>
                {t('could_not_retrieve_data')}
            </div>
        )   : (
            isCountryLoading && !isCountryError ? (
                <div>
                    <Spinner animation="border" variant="secondary" size="sm" /> {t(`${langFiles}.Retrieving_countries`)}
                </div>
            ) : (
                <div>
                    <DropdownButton        
                        variant="light"
                        alignRight
                        title={selectedDisplayCountryName}
                        onSelect={handleCountry}
                        >
                        <Dropdown.Item key={`id_All_countries`} eventKey={t(`${langFiles}.All_countries`)}>{t(`${langFiles}.All_countries`)}</Dropdown.Item>
                        {countryData?.countries?.map(item => (
                            <Dropdown.Item key={`id_${item.country}`} eventKey={item.country}>{item.country}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>
            )
        )
    )
}

export default withTranslation('common')(CountrySelector);
