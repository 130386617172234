import React, { Fragment, useContext, useEffect } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';
import { PricecompareContext } from './PricecompareContext';
import { PaginationContext } from '../contexts/PaginationContext';
import CountrySelector from '../../atomic/organisms/wine/CountrySelector';
import ProductTypeSelector from '../../atomic/organisms/wine/ProductTypeSelector';
import VolumeSelector from '../../atomic/organisms/wine/VolumeSelector';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ComparisationSelector from '../../atomic/organisms/wine/ComparisationSelector';
import SortingSelector from '../../atomic/organisms/wine/SortingSelector';
import CompareProductList from './CompareProductList';
import ProductSearch from '../../atomic/organisms/wine/ProductSearch';

const PricecompareProductListProvider = (props) => {
    const { t } = useTranslation();
    const { isActiveTeamLoading, isActiveTeamError } = useContext(TeamContext);
    const { data, isLoading, isError, changeData , 
            countryData, isCountryLoading, isCountryError, selectedCountryName, setSelectedCountryName, selectedDisplayCountryName, setSelectedDisplayCountryName,
            volumeData, isVolumeError, changeVolumeData, selectedVolume, setSelectedVolume, selectedDisplayVolume, setSelectedDisplayVolume,
            currencyData, isCurrencyLoading, isCurrencyError,
            productTypeData, isProductTypeLoading, isProductTypeError, changeProductTypeData, selectedProductType, setSelectedProductType, selectedDisplayProductType, setSelectedDisplayProductType,
            selectedComparisation, setSelectedComparisation, selectedDisplayComparisation, setSelectedDisplayComparisation,
            sortingRadioValue, setSortingRadioValue,
            productSearchValue, setProductSearchValue, productSearchDisplayValue, setProductSearchDisplayValue
    } = useContext(PricecompareContext);
    const { section, setSection } = useContext(PaginationContext);
    const maxRows = 35;
    !section && setSection(1);

    useEffect(() => {
        changeData(
            [
                {
                    name: 'maxRows',
                    value: maxRows
                },
                {
                    name: 'compare',
                    value: selectedComparisation
                },
                {
                    name: 'country',
                    value: selectedCountryName
                },
                {
                    name: 'productType',
                    value: selectedProductType
                },
                {
                    name: 'volume',
                    value: selectedVolume
                },
                {
                    name: 'section',
                    value: section
                },
                {
                    name: 'orderBy',
                    value: sortingRadioValue
                },
                {
                    name: 'searchText',
                    value: productSearchValue
                }
            ]
        );
    }, [
        changeData, 
        productSearchValue, 
        section, 
        selectedComparisation, 
        selectedCountryName, 
        selectedProductType, 
        selectedVolume, 
        sortingRadioValue
    ]);

    useEffect(() => {
        changeProductTypeData(
            [
                {
                    name: 'country',
                    value: selectedCountryName
                },
                {
                    name: 'volume',
                    value: selectedVolume
                }
            ]
        );
    }, [changeProductTypeData, selectedCountryName, selectedVolume]);

    useEffect(() => {
        changeVolumeData(
            [
                {
                    name: 'country',
                    value: selectedCountryName
                },
                {
                    name: 'productType',
                    value: selectedProductType
                }
            ]
        );
    }, [changeVolumeData, selectedCountryName, selectedProductType]);

    return (
        isActiveTeamLoading && !isActiveTeamError ? (
            <div>
            <Spinner animation="border" variant="secondary" size="sm" /> {t(`retrieving_team_information`)}
            </div>
        ) : (
            <Fragment>
                <div className="jumbotron kw-title">
                    <h5>{t(`heading.price_comparison_norway_sweeden`)}</h5>
                </div>

                <Row xs={1} md={2}> 
                    <Col>
                        <CountrySelector countryData = {countryData} 
                                         isCountryLoading = {isCountryLoading} 
                                         isCountryError = {isCountryError} 
                                         setSelectedCountryName = {setSelectedCountryName} 
                                         selectedDisplayCountryName = {selectedDisplayCountryName} 
                                         setSelectedDisplayCountryName = {setSelectedDisplayCountryName} 
                                         setSection = {setSection} 
                                         />
                    </Col>
                    <Col>
                        <ProductTypeSelector productTypeData = {productTypeData} 
                                             isProductTypeLoading = {isProductTypeLoading} 
                                             isProductTypeError = {isProductTypeError} 
                                             setSelectedDisplayProductType = {setSelectedDisplayProductType} 
                                             selectedDisplayProductType = {selectedDisplayProductType} 
                                             setSelectedProductType = {setSelectedProductType}
                                             selectedProductType = {selectedProductType}
                                             setSection = {setSection} 
                                             />
                    </Col>
                </Row>
                <Row xs={1} md={2}> 
                    <Col>
                        <VolumeSelector volumeData = {volumeData}
                                        isVolumeError = {isVolumeError} 
                                        selectedDisplayVolume = {selectedDisplayVolume} 
                                        setSelectedDisplayVolume = {setSelectedDisplayVolume} 
                                        selectedVolume = {selectedVolume}
                                        setSelectedVolume = {setSelectedVolume}
                                        setSection = {setSection}
                                        />
                    </Col>
                    <Col>
                        <div>
                            <ComparisationSelector  setSelectedComparisation = {setSelectedComparisation}
                                                    selectedDisplayComparisation = {selectedDisplayComparisation}
                                                    setSelectedDisplayComparisation = {setSelectedDisplayComparisation} 
                                                    setSection = {setSection}
                                                    />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br />
                        <div className='text-center'>
                            <SortingSelector  sortingRadioValue = {sortingRadioValue} 
                                              setSortingRadioValue = {setSortingRadioValue}/>
                            <br />
                            <br />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='text-center'>
                            <ProductSearch  productSearchValue = {productSearchValue} 
                                            setProductSearchValue = {setProductSearchValue}
                                            productSearchDisplayValue = {productSearchDisplayValue}
                                            setProductSearchDisplayValue = {setProductSearchDisplayValue}
                                            setSection = {setSection}
                                            />
                            <br />
                            <br />
                        </div>
                    </Col>
                </Row>

                <CompareProductList data = {data}
                                    isLoading = {isLoading}
                                    isError = {isError}
                                    currencyData = {currencyData} 
                                    isCurrencyLoading = {isCurrencyLoading}
                                    isCurrencyError = {isCurrencyError}
                                    section = {section}
                                    setSection = {setSection}
                                    maxRows = {maxRows} />

            </Fragment>
        )
    );
}
 
export default withTranslation('common')(PricecompareProductListProvider);