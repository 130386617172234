import React from 'react'

const WinetestDetails = (props) => {
    const id = props.match.params.id;
    return (
        <div className="container section winetest-details">
            <div className="card z-depth-0">
                <div className="card-content">
                    <span className="card-title">Test tittel - {id}</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo dolores labore quas, sapiente earum explicabo est iusto quae, ducimus at illum accusamus adipisci dignissimos obcaecati, voluptates magni? Nobis, neque. Incidunt?</p>
                </div>
                <div className="card-action grey lighten-4 grey-text">
                    <div>Postet av Lasse</div>
                    <div>2. mars, 14:00</div>
                </div>
            </div>
        </div>
    )
}

export default WinetestDetails




