import React, { createContext, useState } from 'react';

export const PaginationContext = createContext();

const PaginationContextProvider = (props) => {

    const [section, setSection] = useState(1);

    return (
        <PaginationContext.Provider value={{section, setSection}}>
            {props.children}
        </PaginationContext.Provider>
    )
}


export default PaginationContextProvider;
