import React, {useContext} from 'react';
import Pagination  from 'react-bootstrap/Pagination';
import { TeamContext } from "../../components/contexts/TeamContext";
import Row  from 'react-bootstrap/Row';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function KwPagination({setSection, maxRows, totalRows, activeSection}) {
    const { activeTeam } = useContext(TeamContext);
    const sectionsTotal = Math.ceil(totalRows / maxRows);
    const showPagination = sectionsTotal > 1;
    const nextPage = activeSection < sectionsTotal ? activeSection + 1 : 0;
    const prevPage = activeSection > 1 ? activeSection - 1 : 0;
    const { width } = useWindowDimensions();
    let maxNumberOfSectionsBetween = Math.ceil(width / 47) - 6;
    if(maxNumberOfSectionsBetween >= sectionsTotal) {
        maxNumberOfSectionsBetween = sectionsTotal -1;
    };




    //Viser maks maxNumberOfSectionsBetween seksjoner pluss første og siste
    let startSec=activeSection-2;
    let endSec=startSec;

    try {
        if(startSec > sectionsTotal - maxNumberOfSectionsBetween){
            startSec = sectionsTotal - maxNumberOfSectionsBetween;
        }
        if(startSec < 2) {
            startSec=2;
        }

        endSec=startSec + maxNumberOfSectionsBetween;
        if(endSec >= sectionsTotal) {
            endSec = sectionsTotal - 1;
        }
    } catch (e) {
        console.log("KwPagination feil : " + e);
    }

    const sectionsBetween = [];
    for (let i=startSec; i<=endSec; i++) {
        if(i === activeSection) {
            sectionsBetween.push(<Pagination.Item onClick={() => setSection(i)} key={i} active>{i}</Pagination.Item>);
        } else {
            sectionsBetween.push(<Pagination.Item onClick={() => setSection(i)} key={i}>{i}</Pagination.Item>);
        }
    }

    // console.log('setSection = ', setSection);
    // console.log('maxRows = ', maxRows);
    // console.log('totalRows = ', totalRows);
    // console.log('sectionsTotal = ', sectionsTotal);
    // console.log('activeSection = ', activeSection);
    // console.log('Total Width = ', width);
    // console.log('maxNumberOfSectionsBetween = ', maxNumberOfSectionsBetween);
    // console.log('endSec = ', endSec);
    
    return (
        <div className={activeTeam.page.design.stylesheet}>
        {showPagination ? (
            <Row className="justify-content-center pt-2">
                <Pagination>
                    {/* <Pagination.First /> */}
                    {prevPage > 0 ? <Pagination.Prev onClick={() => setSection(prevPage)} /> : <Pagination.Prev disabled />}
                    {activeSection === 1 ? (
                        <Pagination.Item onClick={() => setSection(1)} active>{1}</Pagination.Item>
                    ) : (
                        <Pagination.Item onClick={() => setSection(1)}>{1}</Pagination.Item>
                    )}
                    
                    {startSec > 2 && <Pagination.Ellipsis disabled/>}
                
                    {sectionsBetween}
                
                    {endSec < sectionsTotal - 1 && <Pagination.Ellipsis disabled/>}

                    {activeSection === sectionsTotal ? (
                        <Pagination.Item onClick={() => setSection(sectionsTotal)} active>{sectionsTotal}</Pagination.Item>
                    ) : (
                        <Pagination.Item onClick={() => setSection(sectionsTotal)}>{sectionsTotal}</Pagination.Item>
                    )}
                    
                    {nextPage > 0 ? <Pagination.Next onClick={() => setSection(nextPage)} /> : <Pagination.Next disabled />}
                    
                    {/* <Pagination.Last /> */}
                </Pagination>
            </Row>
            ) : (
                null
            )
        }
        </div>
    )
}

export default KwPagination;
