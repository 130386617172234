// import { useEffect, useState } from 'react';
// import axios from 'axios';

// const useHackerNewsApi = () => {
//     const [data, setData] = useState({ hits: [] });
//     const [url, setUrl] = useState(
//       'https://hn.algolia.com/api/v1/search?query=redux',
//     );
//     const [isLoading, setIsLoading] = useState(false);
//     const [isError, setIsError] = useState(false);
//     useEffect(() => {
//       const fetchData = async () => {
//         setIsError(false);
//         setIsLoading(true);
//         try {
//           const result = await axios(url);
//           setData(result.data);
//         } catch (error) {
//           setIsError(true);
//         }
//         setIsLoading(false);
//       };
//       fetchData();
//     }, [url]);
//     return [{ data, isLoading, isError }, setUrl];
//   }

//   export default useHackerNewsApi;


import { useEffect, useState, useReducer } from 'react';
import axios from 'axios';

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
          return {
               ...state,
               isLoading: true,
               isError: false, 
            };
        case 'FETCH_SUCCESS':
            //console.log('Data hentet OK', action);
            return { 
                ...state,
                isLoading: false,
                isError: false, 
                data: action.payload,
          };
        case 'FETCH_FAILURE':
            return { ...state,
                isLoading: false,
                isError: true, 
            };
        default:
            throw new Error();
    }
};

const useDataApi = (initialUrl, initialData) => {
    const [url, setUrl] = useState(initialUrl);

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: true,
        isError: false,
        data: initialData,
    });
  
    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT'});
            try {
                let result = null;
                if(url.startsWith("http")) {
                    result = await axios(url);
                } else {
                    result = await axios(`${process.env.REACT_APP_HOST_API_URL}/${url}`);
                }

                if (!didCancel) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data});
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: 'FETCH_FAILURE' });
                }
            }
        };
        fetchData();

        /**
         * Litt usikker på dette med didCancel. Har brukt det jeg fant i denne artikkelen :
         * https://www.robinwieruch.de/react-hooks-fetch-data
         * Det som returneres her er så vidt jeg skjønner cleanup-funksjonen som skal 
         * returneres fra useEffect.
         */
        

        return () => {
            didCancel = true;
        }
    }, [url]);

    return [state, setUrl];
  }

  export default useDataApi;