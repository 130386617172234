import React, { Fragment, useContext, useState, useEffect } from 'react';
import useDataForTeamApi from '../api/DataForTeamApi';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import { TeamContext } from "../contexts/TeamContext";
import AppHeader from "../header/AppHeader";
//import FbShare from '../facebook/FbShare';
import FbLike from '../facebook/FbLike';

const ComparisonProvider = (props) => {
    const { activeTeam } = useContext(TeamContext);
    const langFiles = 'pricecompare';
    const { t } = useTranslation();
    const history = useHistory();
    const [shareLink, setShareLink] = useState('');
    
    useEffect(() => {
        let tmpLink  = window.location.host + "/price_compare/" + props.match.params.productId;
        //let tmpLink = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/price_compare/' + props.match.params.productId + '?lag=' + {activeTeam.team.id};
        

        if(activeTeam?.team?.id) {
            tmpLink = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/price_compare/' + props.match.params.productId + '?lag=' + activeTeam.team.id;
            process.env.NODE_ENV === "development" && (
                tmpLink = `https://klubbweb.no/?cmd=wine_compare_details&lag=${activeTeam.team.id}&ukobletProduktId=${props.match.params.productId}`
            );
        };



        // process.env.NODE_ENV === "development" && (
        //     tmpLink = `https://klubbweb.no/?cmd=wine_compare_details&lag=${activeTeam?.team?.id}&ukobletProduktId=${props.match.params.productId}`
        // );
        setShareLink(tmpLink);

        //setShareLink(window.location.host + "/news/" + article.newsId);
        //setShareLink("https://klubbweb.no/?cmd=news_show_one&lag=106&activeId=" + article.newsId);
    }, [activeTeam, props.match.params.productId]);

    const [{ data, isLoading, isError }] = useDataForTeamApi(
        'wineprice/getMatchedProducts',
        [
            {
                name: "productId",
                value: props.match.params.productId
            },
        ]
    );

    const goback = (e) => {
        history.goBack();
    };


    return (
        <Fragment>
            {isError && <div>{t('could_not_retrieve_data')}</div>}
            {isLoading && !isError ? (
                <div>
                    <Spinner animation="border" variant="secondary" size="sm" />
                    {t('retrieving_article')}
                </div>
                
            ) : (
                <>
                <AppHeader ogTitle={data.mainProduct?.productName} 
                           ogDescription={data?.mainProduct?.productName}
                           ogImage={data.ogImage}
                           //ogImage={`https://bilder.vinmonopolet.no/cache/1200x1200-0/${data.mainProduct.productNumber}-1.jpg`}
                           />
                <div className={activeTeam.page.design.stylesheet}>
                    <div className="card">
                        <div className="card-header text-center">
                            {data.mainProduct.productName != null ? (
                                <h3>
                                    {data.mainProduct.productName}
                                </h3>
                            ) : (
                                <div className="col-sm-12">
                                    <h3>{t(`${langFiles}.Data_about_product_is_missing`)}</h3>
                                </div>
                            )}
                        </div>

                        <div className="card-body">
                            <div className="row underlined bold">
                                <div className="col-6 text-right border-right">
                                    Vinmonopolet
                                </div>
                                <div className="col-6 text-left">
                                    Systembolaget
                                </div>
                            </div>
                                

                            <div className="row">
                                <div className="col-6 text-right border-right"> 
                                    {data.mainProduct != null ? (
                                        <img src={data.imageFileVP} height="200" alt="" />
                                        //<img src={`https://bilder.vinmonopolet.no/cache/1200x1200-0/${data.mainProduct.productNumber}-1.jpg`} height="200" alt="" />
                                    ) : (
                                        t(`${langFiles}.No_more_at_VP`)
                                    )}
                                </div>
                                <div className="col-6 text-left"> 
                                    {data.twinProduct != null ? (
                                        <img src={data.imageFileSB} height="200" alt="" />
                                    ) : (
                                        t(`${langFiles}.No_more_at_SB`)
                                    )}
                                </div>
                            </div>
                                
                            <div className="row">
                                <div className="col-12 text-center p-2">
                                    {/* <FbShare linkToShare={shareLink} /> */}
                                    <FbLike linkToShare={shareLink} buttonSize="small" className="justify-content-center"/>
                                    
                                    <div className="fb-share-button" 
                                        data-href="<%=fbHref%>" 
                                        data-layout="button_count" 
                                        data-size="large">
                                        <a target="_blank" 
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                                        rel="noopener noreferrer"
                                        className="fb-xfbml-parse-ignore">Del</a>
                                    </div>
                                    {/* <%if(fbHref != null) {%>
                                        <div className="fb-share-button" 
                                            data-href="<%=fbHref%>" 
                                            data-layout="button_count" 
                                            data-size="large">
                                            <a target="_blank" 
                                            href="https://www.facebook.com/sharer/sharer.php?u=<%=fbHref%>" 
                                            className="fb-xfbml-parse-ignore">Del</a>
                                        </div>
                                    <%}%> */}
                                </div>
                            </div>

                            {/* <%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getProdusentKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.producerClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Producer`)}
                                </div>
                                <div className="col-6 text-right border-right"> 
                                    {data.mainProduct.producer}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.producer}
                                    {data.twinProduct.missingWordInProducername.localeCompare('') !== 0 &&
                                        <div className={`text-${data.twinProduct.classes.producerClass}`}><b>{t(`${langFiles}.Missing`)}: </b>{data.twinProduct.missingWordInProducername}</div>
                                    }
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getProdusentKlasseBS();
                                }%> */}
                            </div>

                            {/* <%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getVarenavnKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.productnameClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Name`)}
                                </div>
                                <div className="col-6 text-right border-right"> 
                                    <a className="bold" 
                                       href={`https://www.vinmonopolet.no/p/${data.mainProduct.productNumber}`} 
                                       target="_blank"
                                       rel="noopener noreferrer">
                                           {data.mainProduct.productName}
                                    </a>
                                    {/* <a className="bold" href="https://www.vinmonopolet.no/p/<%=ukobletProdukt.getVarenr()%>" target=\"_blank\"><%=ukobletProdukt.getVarenavn()%></a> */}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct != null && (
                                        <>
                                        <a className="bold" 
                                           href={`https://www.systembolaget.se/${data.twinProduct.productNumber}`} 
                                           target="_blank"
                                           rel="noopener noreferrer">
                                               {data.twinProduct.productName}
                                        </a>
                                        {data.twinProduct.missingWordInName.localeCompare('') !== 0 && (
                                            <div className={`text-${data.twinProduct.classes.productClass}`}><b>{t(`${langFiles}.Missing`)}: </b>{data.twinProduct.missingWordInName}</div>
                                        )}
                                        </>
                                    )} 
                                    {/* <%if(tvillingProdukt!=null) {%>
                                        <a className="bold" href="https://www.systembolaget.se/<%=tvillingProdukt.getVarenr()%>" target=\"_blank\"><%=tvillingProdukt.getVarenavn()%></a>
                                        <%if(!tvillingProdukt.getManglendeOrd().equals("")) {%>
                                            <div className="text-<%= tvillingProdukt.getVarenavnKlasseBS()%>"><b>Mangler: </b><%=tvillingProdukt.getManglendeOrd()%></div>
                                        <%}%>
                                    <%}%>  */}
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getVarenavnKlasseBS();
                                }%> */}
                            </div>

                            <div className="row alert">
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Product_number`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    <a className="bold" 
                                       href={`https://www.vinmonopolet.no/p/${data.mainProduct.productNumber}`} 
                                       target="_blank" 
                                       rel="noopener noreferrer">
                                           {data.mainProduct.productNumber}
                                    </a>
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.productNumber !== null && (
                                        <a className="bold" 
                                           href={`https://www.systembolaget.se/${data.twinProduct.productNumber}`} 
                                           target="_blank"
                                           rel="noopener noreferrer">
                                               {data.twinProduct.productNumber}
                                        </a>
                                    )}
                                </div>
                            </div>

                            {/* <%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getLandKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.countryClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Country`)}
                                </div>
                                <div className="col-6 text-right border-right"> 
                                    {data.mainProduct.origin.country} 
                                </div>
                                <div className="col-6"> 
                                    {data.twinProduct.origin.country !== null && (
                                        data.twinProduct.origin.country
                                    )}
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getLandKlasseBS();
                                }%> */}
                            </div>

                            {/* <%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getDistriktKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.districtClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.District`)}
                                </div>
                                <div className="col-6 text-right border-right">  
                                    {data.mainProduct.origin.district} 
                                </div>
                                <div className="col-6"> 
                                    {data.twinProduct.origin.district !== null && (
                                        data.twinProduct.origin.district
                                    )}
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getDistriktKlasseBS();
                                }%> */}
                            </div>

                            <div className="row alert">
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Sub_district`)}
                                </div>
                                <div className="col-6 text-right border-right">  
                                    {data.mainProduct.origin.subDistrict}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.origin.subDistrict}
                                </div>
                                <div className="col-12 text-center"> 
                                </div>
                            </div>

                            <div className={`row alert alert-${data.twinProduct.classes.productTypeClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Product_type`)}
                                </div>
                                <div className="col-6 text-right border-right">   
                                    {data.mainProduct.productType}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.productType}
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getProdukt_typeKlasseBS();
                                }%> */}
                            </div>

                            {/* <%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getAargangKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.vintageClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Vintage`)}
                                </div>
                                <div className="col-6 text-right border-right"> 
                                    {data.mainProduct.vintage !== 0 && (
                                        data.mainProduct.vintage
                                    )}
                                </div>
                                <div className="col-6"> 
                                    {data.twinProduct.vintage !== 0 && (
                                        data.twinProduct.vintage
                                    )} 
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getAargangKlasseBS();
                                }%> */}
                                <div className={`col-12 text-center`}>
                                    {t(`${langFiles}.diffMessages.${data.twinProduct.messages.vintageMessage}`)}
                                </div>
                            </div>

                            {/*<%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getRastoffKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.feedstockClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Feedstock`)}
                                </div>
                                <div className="col-6 text-right border-right">   
                                    {data.mainProduct.feedstock}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.feedstock}
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getRastoffKlasseBS();
                                }%> */}
                            </div>

                            {/*<%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getAlkoholKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.alcoholClass}`}>
                                <div className="col-12 text-center bold">
                                    % {t(`${langFiles}.titles.Alcohol`).toLowerCase()}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.alcohol.toLocaleString('no-NO', {minimumFractionDigits: 2})}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.alcohol.toLocaleString('no-NO', {minimumFractionDigits: 2})}
                                </div>
                                {/* <%if(tvillingProdukt!=null) {
                                    klasse = "text-" + tvillingProdukt.getAlkoholKlasseBS();
                                }%> */}
                                {/* <div className={`col-12 text-center text-${data.twinProduct.classes.alcoholClass}`}>  */}
                                <div className={`col-12 text-center`}> 
                                    {data.twinProduct.messages.alcoholMessage !== '' && (
                                        t(`${langFiles}.diffMessages.${data.twinProduct.messages.alcoholMessage}`)
                                    )}
                                </div>
                            </div>

                            {/* <%if(tvillingProdukt!=null) {
                                klasse = "alert alert-" + tvillingProdukt.getVolumKlasseBS();
                            }%> */}
                            <div className={`row alert alert-${data.twinProduct.classes.volumeClass}`}>
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Volume`)}({t(`${langFiles}.titles.cl`)}.)
                                </div>
                                <div className="col-6 text-right border-right"> 
                                    {data.mainProduct.volume}
                                    {/* <%if(tvillingProdukt!=null) {%>
                                        <%=tallFormat.format(ukobletProdukt.getVolum(),"0")%>
                                    <%}%>  */}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.volume}
                                    {/* <%if(tvillingProdukt!=null) {%>
                                        <%=tallFormat.format(tvillingProdukt.getVolum(),"0")%>
                                    <%}%> */}
                                </div>
                            </div>

                            <div className="row alert">
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Packaging`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.packaging}
                                </div>
                                <div className="col-6">
                                    {data.twinProduct.packaging}
                                </div>
                            </div>

                            <div className="row alert">
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Cork_type`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.corkType}
                                </div>
                                <div className="col-6">
                                    {data.twinProduct.corkType}
                                </div>
                            </div>

                            <div className="row alert">
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Price`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.price.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                                </div>
                                <div className="col-6">  
                                    {/* {(data.twinProduct.price * currencyData?.currency?.obsValue / 100).toLocaleString('no-NO', {style:'currency', currency:'NOK'}) (currencyData?.currency?.baseCur data.twinProduct.price).toLocaleString('no-NO', {style:'currency', currency:'NOK')} */}
                                    {`${(data.twinProduct.price * data.currency.obsValue / 100).toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.baseCur} ${data.twinProduct.price.toLocaleString('no-NO', {style:'currency', currency:'NOK'})})`}
                                    {/* <%if(tvillingProdukt!=null) {%>
                                         <%=tallFormat.format(tvillingProdukt.getPris() * valuta.getObsValue(100),"0,00")%> (<%=valuta.getBaseCur()%> <%=tallFormat.format(tvillingProdukt.getPris(),"0,00")%>)
                                    <%}%>  */}
                                </div>
                                <div className="col-12">&nbsp;</div>
                            </div>

                            <div className="row alert text-secondary">
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Taxes`)}
                                </div>
                                <div className="col-12  text-center">
                                    {t(`${langFiles}.titles.Alcohol_tax`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.taxes.alcoholTaxes === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${data.mainProduct.taxes.alcoholTaxes.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}`
                                    )}
                                    {/* <%if (ukobletProdukt.getAvgiftBean().getAlkoholavgift()== -1) {%>
                                        <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                    <%} else {%>
                                        <%=tallFormat.format(ukobletProdukt.getAvgiftBean().getAlkoholavgift(),"0,00")%>
                                    <%}%> */}
                                </div>
                                <div className="col-6"> 
                                    {data.twinProduct.taxes.alcoholTaxes === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${data.twinProduct.taxes.alcoholTaxes.toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.quoteCur})`
                                        //`${data.twinProduct.taxes.alcoholTaxes.toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.quoteCur})`
                                    )} 
                                    {/* <%if(tvillingProdukt!=null) {
                                        if (tvillingProdukt.getAvgiftBean().getAlkoholavgift()== -1) {%>
                                            <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                        <%} else {%>
                                            <%=tallFormat.format(tvillingProdukt.getAvgiftBean().getAlkoholavgift(),"0,00")%> (<%=valuta.getQuoteCur()%>)
                                        <%}
                                    }%>  */}
                                </div>
                                
                                <div className="col-12 text-center">
                                    {t(`${langFiles}.titles.Packaging_fee`)}
                                </div>
                                <div className="col-6 text-right border-right">  
                                    {data.mainProduct.taxes.packagingFee === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        data.mainProduct.taxes.packagingFee.toLocaleString('no-NO', {style:'currency', currency:'NOK'})
                                    )}
                                    {/* bletProdukt.getAvgiftBean().getEmballasjeavgift()== -1) {%>
                                        <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                    <%} else {%>
                                        <%=tallFormat.format(ukobletProdukt.getAvgiftBean().getEmballasjeavgift(),"0,00")%>
                                    <%}%> */}
                                </div>
                                <div className="col-6">  
                                    {data.twinProduct.taxes.packagingFee === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${data.twinProduct.taxes.packagingFee.toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.quoteCur})`
                                    )}
                                    {/* <%if(tvillingProdukt!=null) {
                                        if (tvillingProdukt.getAvgiftBean().getEmballasjeavgift()== -1) {%>
                                            <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                        <%} else {%>
                                            <%=tallFormat.format(tvillingProdukt.getAvgiftBean().getEmballasjeavgift(),"0,00")%> (<%=valuta.getQuoteCur()%>)
                                        <%}
                                    }%>  */}
                                </div>
                                
                                <div className="col-12 text-center">
                                    {t(`${langFiles}.titles.Fees_in_total`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.taxes.alcoholTaxes === -1 || data.mainProduct.taxes.packagingFee === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${(data.mainProduct.taxes.alcoholTaxes + data.mainProduct.taxes.packagingFee).toLocaleString('no-NO', {style:'currency', currency:'NOK'})}`
                                    )}

                                    {/* <%if (ukobletProdukt.getAvgiftBean().getAlkoholavgift()== -1 || ukobletProdukt.getAvgiftBean().getEmballasjeavgift()== -1) {%>
                                        <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                    <%} else {%>
                                        <%=tallFormat.format(ukobletProdukt.getAvgiftBean().getAlkoholavgift() + ukobletProdukt.getAvgiftBean().getEmballasjeavgift(),"0,00")%>
                                    <%}%> */}
                                </div>
                                <div className="col-6"> 
                                    {data.twinProduct.taxes.alcoholTaxes === -1 || data.twinProduct.taxes.packagingFee === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${(data.twinProduct.taxes.alcoholTaxes + data.twinProduct.taxes.packagingFee).toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.quoteCur})`
                                    )} 
                                    {/* <%if(tvillingProdukt!=null) {
                                        if (tvillingProdukt.getAvgiftBean().getAlkoholavgift()== -1 || tvillingProdukt.getAvgiftBean().getEmballasjeavgift()== -1) {%>
                                            <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                        <%} else {%>
                                            <%=tallFormat.format(tvillingProdukt.getAvgiftBean().getAlkoholavgift() + tvillingProdukt.getAvgiftBean().getEmballasjeavgift(),"0,00")%> (<%=valuta.getQuoteCur()%>)
                                        <%}
                                    }%>  */}
                                </div>
                            </div>

                            <div className="row alert text-secondary">
                                
                                <div className="col-12 text-center bold">
                                    {t(`${langFiles}.titles.Price_without_taxes`)}
                                </div>
                                <div className="col-6 text-right border-right">
                                    {data.mainProduct.taxes.alcoholTaxes === -1 || data.mainProduct.taxes.packagingFee === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${(data.mainProduct.price - data.mainProduct.taxes.alcoholTaxes - data.mainProduct.taxes.packagingFee).toLocaleString('no-NO', {style:'currency', currency:'NOK'})}`
                                    )}

                                    {/* <%if (ukobletProdukt.getAvgiftBean().getAlkoholavgift()== -1 || ukobletProdukt.getAvgiftBean().getEmballasjeavgift()== -1) {%>
                                        <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                    <%} else {%>
                                        <%=tallFormat.format(ukobletProdukt.getPris() - ukobletProdukt.getAvgiftBean().getAlkoholavgift() - ukobletProdukt.getAvgiftBean().getEmballasjeavgift(),"0,00")%>
                                    <%}%> */}
                                </div>
                                <div className="col-6">
                                    {data.twinProduct.taxes.alcoholTaxes === -1 || data.twinProduct.taxes.packagingFee === -1 ? (
                                        t(`${langFiles}.titles.Unknown`)
                                    ) : (
                                        `${((data.twinProduct.price * data.currency.obsValue / 100) - data.twinProduct.taxes.alcoholTaxes - data.twinProduct.taxes.packagingFee).toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.quoteCur})`
                                        // `${((data.twinProduct.price * data.currency.obsValue / 100) - data.twinProduct.taxes.alcoholTaxes - data.twinProduct.taxes.packagingFee).toLocaleString('no-NO', {style:'currency', currency:'NOK'})} (${data.currency.baseCur} ${data.twinProduct.price.toLocaleString('no-NO', {style:'currency', currency:'NOK'})})`
                                    )}  
                                    {/* <%if(tvillingProdukt!=null) {
                                        if (tvillingProdukt.getAvgiftBean().getAlkoholavgift()== -1 || tvillingProdukt.getAvgiftBean().getEmballasjeavgift()== -1) {%>
                                            <%= textBean.getText("wine.AlcoholTaxUnknown") %>
                                        <%} else {%>
                                            <%=tallFormat.format((tvillingProdukt.getPris() * valuta.getObsValue(100)) - tvillingProdukt.getAvgiftBean().getAlkoholavgift() - tvillingProdukt.getAvgiftBean().getEmballasjeavgift(),"0,00")%> (<%=valuta.getQuoteCur()%>)
                                        <%}
                                    }%>  */}
                                </div>
                            </div>
                            <FbLike linkToShare={shareLink} buttonSize="small" className="justify-content-center"/>
                                
                            {/*<div className="row">
                                <div className="col-12 text-center">
                                    <%if(fbHref != null) {%>
                                        <div className="fb-share-button" 
                                            data-href="<%=fbHref%>" 
                                            data-layout="button_count" 
                                            data-size="large">
                                            <a target="_blank" 
                                            href="https://www.facebook.com/sharer/sharer.php?u=<%=fbHref%>" 
                                            className="fb-xfbml-parse-ignore">Del</a>
                                        </div>
                                    <%}%>
                                </div>
                            </div> */}











                                
                        </div>
                    </div>    
                    {/* <div className="card-body">
                        <!-- DATA OM PRODUKTET SOM SKAL SAMMENLIGNES -->
                        <%if(ukobletProdukt.getVarenavn()!=null){%>
                            <input type="hidden" name="ukobletProduktId" value="<%=ukobletProdukt.getId()%>" />
                                <jsp:include page="vin_match_include.jsp" flush="true" />
                        <%}%>


                        <div className="row">
                            <br />
                        </div>

                        <!--div className="col-12 text-center">
                            <a href="javascript:history.back()" target="_self">&laquo;<%=textBean.getText("button.return")%></a>
                        </div-->
                    </div> */}
                </div>









                {/* // <div className={activeTeam.page.design.stylesheet}>
                //     {data.news.map(item => (
                //         <NewsDetails key={item.newsId} article={item} forceShowText/>
                //     ))}
                //     <Row className="justify-content-center">
                //         <div className='kw-backbutton' onClick={goback}>
                //             <p><FontAwesomeIcon icon={faArrowLeft} /> {t('history_back')}</p>
                //         </div>
                //     </Row>
                // </div> */}
                </>
            )}
            
            <Row className="justify-content-center">
                <div className='kw-backbutton' onClick={goback}>
                    <p><FontAwesomeIcon icon={faArrowLeft} /> {t('history_back')}</p>
                </div>
            </Row>
        </Fragment>
    );
}
 
export default withTranslation('common')(ComparisonProvider);