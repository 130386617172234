import no from './no/common.json';
import se from './se/common.json';
import en from './en/common.json';

import navbarNo from '../../components/navbar/locales/no/common.json';
import navbarSe from '../../components/navbar/locales/se/common.json';
import navbarEn from '../../components/navbar/locales/en/common.json';

import notFoundNo from '../../components/notFound/locales/no/common.json';
import notFoundSe from '../../components/notFound/locales/se/common.json';
import notFoundEn from '../../components/notFound/locales/en/common.json';

import teamMenuNo from '../../components/teamMenu/locales/no/common.json';
import teamMenuSe from '../../components/teamMenu/locales/se/common.json';
import teamMenuEn from '../../components/teamMenu/locales/en/common.json';

import pricecompareNo from '../../components/pricecompare/locales/no/common.json';
import pricecompareSe from '../../components/pricecompare/locales/se/common.json';
import pricecompareEn from '../../components/pricecompare/locales/en/common.json';

// import pt from './default.json';
// import en from './default.en.json';
// import homePt from '../../components/Home/locales/default.json';
// import homeEn from '../../components/Home/locales/default.en.json';
// import augustinePt from '../../components/lighthouses/Augustine/locales/default.json';
// import augustineEn from '../../components/lighthouses/Augustine/locales/default.en.json';
// import herculesPt from '../../components/lighthouses/Hercules/locales/default.json';
// import herculesEn from '../../components/lighthouses/Hercules/locales/default.en.json';
// import lindauPt from '../../components/lighthouses/Lindau/locales/default.json';
// import lindauEn from '../../components/lighthouses/Lindau/locales/default.en.json';
// import peggysPt from '../../components/lighthouses/Peggys/locales/default.json';
// import peggysEn from '../../components/lighthouses/Peggys/locales/default.en.json';

no.navbar = navbarNo;
se.navbar = navbarSe;
en.navbar = navbarEn;

no.notFound = notFoundNo;
se.notFound = notFoundSe;
en.notFound = notFoundEn;

no.teamMenu = teamMenuNo;
se.teamMenu = teamMenuSe;
en.teamMenu = teamMenuEn;

no.pricecompare = pricecompareNo;
se.pricecompare = pricecompareSe;
en.pricecompare = pricecompareEn;

// pt['pt-BR'].home = homePt['pt-BR'];
// pt['pt-BR'].lighthouses.augustine = augustinePt;
// pt['pt-BR'].lighthouses.hercules = herculesPt;
// pt['pt-BR'].lighthouses.lindau = lindauPt;
// pt['pt-BR'].lighthouses.peggys = peggysPt;
// en['en'].home = homeEn['en'];
// en['en'].lighthouses.augustine = augustineEn;
// en['en'].lighthouses.hercules = herculesEn;
// en['en'].lighthouses.lindau = lindauEn;
// en['en'].lighthouses.peggys = peggysEn;

export {
  no,
  se,
  en,
};