import React, { useContext } from 'react';
import KwImage from "../../atomic/atoms/Kw_Image";
import Ingress from '../../atomic/atoms/Ingress';
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope } from '@fortawesome/free-regular-svg-icons';

const NewsDetails = ({ article, forceShowText }, context) => {
    const { activeTeam, isLoading, isError } = useContext(TeamContext);
    const history = useHistory();
    
    if (forceShowText) {
        article.view=1;
    };

    function Content() {
        return (
            <div className={`card ${activeTeam.page.design.stylesheet}`}>
                <div className="card-body">
                    <div className="card-content grey-text text-darken-3"> 
                        <KwImage 
                            showImage={true}
                            bildeId={article.articleImage.id}
                            alt={article.title}
                            classes='rounded kw-image-article-content'
                            isThumbnail = {true}
                        />
                        <h1 className="card-title kw-tmb-title">{article.title}</h1>
                        <div className="underlined">
                            <small>
                                <FontAwesomeIcon icon={faCalendarAlt} /> {article.dateCreated}
                                &nbsp;
                                <FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${article.author.userId}`}>{article.author.alias}</a>
                                &nbsp;
                            </small>
                        
                            <span className="badge badge-danger right">{article.dateChanged}</span>
                        </div>
                        
                        <Ingress className="card-title kw-ingress" ingressText= { article.ingress} />
                    </div>
                </div>
            </div>
        )
    };

    function redirect() {
        history.push('/news/' + article.newsId + '?lag=' + activeTeam.team.id);
    };
    
    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
        ) : (
            <div className='kw-newslink' onClick={redirect}>
                <Content />
            </div>
        )
     );
}

export default NewsDetails;