import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';
// import { useHistory } from 'react-router-dom';

// import FbShare from '../facebook/FbShare';
// import FbLike from '../facebook/FbLike';

const ProductRow = ({ product, currencyData }, context) => {
    const { activeTeam, isLoading, isError } = useContext(TeamContext);
    // const history = useHistory();
    // const [shareLink, setShareLink] = useState('');
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    // useEffect(() => {
    //     let tmpLink  = window.location.host + "/news/" + article.newsId;
    //     process.env.NODE_ENV === "development" && (
    //         tmpLink = "https://klubbweb.no/?cmd=news_show_one&lag=106&activeId=" + article.newsId
    //     );
    //     setShareLink(tmpLink);

    //     //setShareLink(window.location.host + "/news/" + article.newsId);
    //     //setShareLink("https://klubbweb.no/?cmd=news_show_one&lag=106&activeId=" + article.newsId);
    // }, [article.newsId, shareLink]);

    function Content() {
        return (
            <div className={`row underlined  ${activeTeam.page.design.stylesheet}`}>
                {/* Venstre */}
                <div className="col-sm-6 col-md-7 col-lg-8">
                    <div className ="row">
                        <div className="col-sm-2">
                            <Link to={`/price_compare/${product.productNumber}?lag=${activeTeam.team.id}`}>
                                <img className="d-none d-sm-block img-fluid mx-auto" src={`https://bilder.vinmonopolet.no/cache/300x300-0/${product.productNumber}-1.jpg`} height="100" alt="" />
                                <img className="mx-auto d-sm-none d-block" src={`https://bilder.vinmonopolet.no/cache/300x300-0/${product.productNumber}-1.jpg`} height="100" alt="" />
                                {/* <a href="?cmd=wine_compare_details&amp;lag=<%=request.getParameter("lag")%>&amp;ukobletProduktId=<%=produkt.getVarenr()%>&amp;vinsok=<%=request.getParameter("vinsok")%>&amp;vinsokland=<%=request.getParameter("vinsokland")%>&amp;i=<%=request.getParameter("i")%>" target='_self'>
                                    <img className="d-none d-sm-block img-fluid mx-auto" src="https://bilder.vinmonopolet.no/cache/300x300-0/<%=produkt.getVarenr()%>-1.jpg" height="100" />
                                    <img className="mx-auto d-sm-none d-block" src="https://bilder.vinmonopolet.no/cache/300x300-0/<%=produkt.getVarenr()%>-1.jpg" height="100" />
                                </a> */}
                            </Link>
                        </div>
                        <div className="col-sm-4 col-md-3 d-inline-flex bold">
                            <span className="bold d-block d-sm-none">{t(`${langFiles}.titles.Name`)}:&nbsp;</span>
                            <Link to={`/price_compare/${product.productNumber}?lag=${activeTeam.team.id}`}>
                            {/* <a href="?cmd=wine_compare_details&amp;lag=<%=request.getParameter("lag")%>&amp;ukobletProduktId=<%=produkt.getVarenr()%>&amp;vinsok=<%=request.getParameter("vinsok")%>&amp;vinsokland=<%=request.getParameter("vinsokland")%>&amp;i=<%=request.getParameter("i")%>" target='_self'> */}
                                {product.productName}
                            </Link>
                        </div>
                        <div className="col-sm-3 d-sm-none d-inline-flex d-md-flex">
                            <span className="bold d-block d-sm-none">{t(`${langFiles}.titles.Country`)}/{t(`${langFiles}.titles.District`)}:&nbsp;</span>
                            {product.origin.country}
                            {product.origin.district !== "" ? (
                                <><br />{product.origin.district}</>
                            ) : (
                                product.origin.subDistrict !== "" && (
                                    <><br />{product.origin.subDistrict}</>
                                )
                            )}
                        </div>
                        <div className="col-sm-4 col-md-3 xd-sm-none d-inline-flex d-md-block">
                            <span className="bold d-block d-sm-none">{t(`${langFiles}.titles.Product_type`)}:&nbsp;</span>
                            {product.productType}
                        </div>


                        <div className="col-12 d-sm-none d-flex">
                            <span className="bold d-block">{t(`${langFiles}.titles.Cl`)}:&nbsp;</span>
                            {product.volume}
                        </div>
                        <div className="col-sm-2 col-md-1 d-none d-sm-block text-center">
                            {product.volume}
                        </div>
                    </div>
                </div>

            {/* Høyre */}
            <div className="col-sm-6 col-md-5 col-lg-4">
                <div className ="row"> 
                    {/* Heading for liten skjerm */}
                    <div className="bold container-fluid d-flex d-sm-none">
                        <div className ="col-12 text-center">
                            <h6><small>{t(`${langFiles}.titles.AllPricesInNOK`)} (100 {currencyData?.currency?.baseCur} = {currencyData?.currency?.obsValue.toLocaleString('no-NO', {minimumFractionDigits: 2})} {currencyData?.currency?.quoteCur})</small></h6>
                        </div>
                    </div>
                    <div className="bold container-fluid d-flex d-sm-none">
                        <div className="col-6 text-center">
                            {t(`${langFiles}.titles.Price`)}
                            <hr />
                        </div>
                        <div className="col-6 text-center">
                            {t(`${langFiles}.titles.PriceDifference`)}
                            <hr />
                        </div>
                    </div>
                    <div className="bold container-fluid d-flex d-sm-none">
                        <div className="col-3 text-center">
                            {t(`${langFiles}.titles.Norway`)}
                            <hr />
                        </div>
                        <div className="col-3 text-center">
                            {t(`${langFiles}.titles.Sweden`)}
                            <hr />
                        </div>
                        <div className="col-3 text-center">
                            {t(`${langFiles}.titles.PrVolume`)}
                            <hr />
                        </div>
                        <div className="col-3 text-center">
                            {t(`${langFiles}.titles.PrLitre`)}
                            <hr />
                        </div>
                    </div>
                    {/* Heading for liten skjerm slutt */}

                    <div className="col-3 text-right">
                        {product.price.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                    </div>
                    <div className="col-3 text-right">
                        {(product.priceInSweden * currencyData?.currency?.obsValue / 100).toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                    </div>
                    <div className="col-3 text-right">
                        {product.pricediffPerVolume > 0 ? (
                            <p className="text-danger">
                                {product.pricediffPerVolume.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                                <br />
                                ({(product.pricediffPercentPrVolume * -1).toLocaleString('no-NO', {style:'percent', minimumFractionDigits: 2})})
                            </p>
                        ) : (
                            <p className="text-success">
                                {product.pricediffPerVolume.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                                <br />
                                ({(product.pricediffPercentPrVolume * -1).toLocaleString('no-NO', {style:'percent', minimumFractionDigits: 2})})
                            </p>
                        )}
                    </div>
                    <div className="col-3 text-right">
                        {product.pricediffPerLiter > 0 ? (
                            <p className="text-danger">
                                {product.pricediffPerLiter.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                            </p>
                        ) : (
                            <p className="text-success">
                                {product.pricediffPerLiter.toLocaleString('no-NO', {style:'currency', currency:'NOK'})}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <p></p>
        </div>
        )
    };
    
    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
            
        ) : (
            <div className='kw-card'>
                <Content />
            </div>
        )
     );
}

export default ProductRow;