import React, { createContext, useCallback } from 'react';
import useDataForTeamApi from '../api/DataForTeamApi';

export const NewslistContext = createContext();

const NewslistContextProvider = (props) => {

    const [{ data, isLoading, isError }, changeParams] = useDataForTeamApi(
        'news/getActiveNewsForTeamAndClub',
        []
    );

    const changeData = useCallback((newParams) => {
        changeParams(newParams);
    }, [changeParams]);

    return (
        <NewslistContext.Provider value={{data, isLoading, isError, changeData}}>
            {props.children}
        </NewslistContext.Provider>
    )
}


export default NewslistContextProvider;
