import React, { useContext } from 'react';
import KwImage from "../../atomic/atoms/Kw_Image";
import Ingress from '../../atomic/atoms/Ingress';
import ArticleText from '../../atomic/atoms/ArticleText';
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import FbShare from '../facebook/FbShare';

const SelfdefDetails = ({ article, forceShowText }, context) => {
    const { activeTeam, isLoading, isError } = useContext(TeamContext);
    
    if (forceShowText) {
        article.visning=1;
    };

    function Content() {
        return (
            <div className={`card ${activeTeam.page.design.stylesheet}`}>
                <KwImage 
                    showImage={article.articleImage.format === 'L'}
                    bildeId={article.articleImage.id}
                    alt={article.title}
                    classes='rounded card-img-top'
                />
                <div className="card-body">
                    <div className="card-content grey-text text-darken-3">
                        <h1 className="card-title">{article.title}</h1>
                        <div className="underlined">
                            <small>
                                <FontAwesomeIcon icon={faCalendarAlt} /> {article.dateCreated}
                                &nbsp;
                                <FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${article.author.userId}`}>{article.author.alias}</a>
                                &nbsp;
                            </small>
                        
                            <span className="badge badge-danger right">{article.dateChanged}</span>
                            <FbShare />
                        </div> 
                        <KwImage 
                            showImage={article.articleImage.format === 'P'}
                            bildeId={article.articleImage.id}
                            alt={article.title}
                            classes='rounded kw-image-article-content'
                        />
                        
                        <Ingress className="card-title kw-ingress" ingressText= { article.ingress} />
                        <ArticleText 
                            teamId = { activeTeam.team.id }
                            text = { article.fulltext}
                            view = { article.view } 
                            articleId = { article.pageId }
                            article = {article}
                        />
                    </div>
                </div>
            </div>
        )
    };

    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
        ) : (
            <div className='kw-card'>
                <Content />
            </div>
        )
     );
}

export default SelfdefDetails;