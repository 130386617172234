import React from 'react';
// import i18n from './i18n';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Navbar from './components/navbar/Navbar';
import TeamMenu from './components/teamMenu/TeamMenu';
// import Dashboard from './components/dashboard/Dashboard';
import WinetestDetails from './components/winetests/WinetestDetails';
import NotFound from './components/notFound/NotFound';
import TeamContextProvider from '../src/components/contexts/TeamContext';
import DataTest from '../src/components/testsider/DataTest';
import NewslistContextProvider from './components/news/NewslistContext';
import NewsListProvider from './components/news/NewsList';
import NewsOneProvider from './components/news/NewsOne';
import SelfdeflistContextProvider from './components/selfdefpages/SelfdeflistContext';
import SelfdefListProvider from './components/selfdefpages/SelfdefList';
import SelfdefOneProvider from './components/selfdefpages/SelfdefOne';
import PricecompareContextProvider from './components/pricecompare/PricecompareContext';
import PricecompareProductListProvider from './components/pricecompare/PricecompareProductList';
import ComparisonProvider from './components/pricecompare/Comparison';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/kwstyles.scss';
import DataContextProvider from './components/contexts/DataContext';
import PaginationContextProvider from './components/contexts/PaginationContext';
import FirstpageListProvider from './components/firstpage/Firstpage';
import LikeTeamOnFacebook from './components/facebook/LikeTeamOnFacebook';
import AppHeader from './components/header/AppHeader';
// import { createMemoryHistory } from 'history';

function App(props) {
  // const history=createMemoryHistory();

  return (
    // <BrowserRouter>
    // <Router history={history}>
      <TeamContextProvider>
        <PaginationContextProvider>
          <DataContextProvider>
            <NewslistContextProvider>
              <SelfdeflistContextProvider>
                <PricecompareContextProvider>
                  <div className="App">
                    {process.env.NODE_ENV === "development" && (
                      <h6>{process.env.REACT_APP_HOST_API_URL}</h6>
                    )}
                    <AppHeader />
                    <Navbar />
                    <TeamMenu />
                    <div className="container-fluid kw-content-wrapper">
                      <LikeTeamOnFacebook />
                      <Switch>
                        {/* <Route exact path='/' component={Dashboard} /> */}
                        <Route exact path='/' component={FirstpageListProvider} />
                        {/* <ContextRoute exact path='/list_news_archive' contextComponent={NewslistArchiveContextProvider} component={NewsListArchiveProvider} />
                        <ContextRoute exact path='/news/:newsId' contextComponent={NewslistArchiveContextProvider} component={NewsOneProvider} /> */}
                          {/* <Route exact path='/list_news' component={NewsListProvider} /> */}
                          <Route exact path='/list_news' component={NewsListProvider} />
                          <Route exact path='/news/:newsId' component={NewsOneProvider} />

                          <Route exact path='/list_selfdefpages/:collectionId' component={SelfdefListProvider} />
                          <Route exact path='/selfdefpages/:pageId' component={SelfdefOneProvider} />

                          <Route exact path='/price_compare' component={PricecompareProductListProvider} />
                          <Route exact path='/price_compare/:productId' component={ComparisonProvider} />

                        <Route exact path='/winetests/:id' component={WinetestDetails} />
                        <Route exact path='/list_activities' component={DataTest} />
                        { /* Finally, catch all unmatched routes */ }
                        <Route component={NotFound} />
                      </Switch>

                      <LikeTeamOnFacebook />
                    </div>
                  </div>
                </PricecompareContextProvider>
              </SelfdeflistContextProvider>
            </NewslistContextProvider>
          </DataContextProvider>
        </PaginationContextProvider>
      </TeamContextProvider>
    // </Router>
    // </BrowserRouter>
  );
}

export default withTranslation('common')(App);
