import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
// eslint-disable-next-line
import i18n from './i18n';
// import common_de from "./translations/de/common.json";
// import common_en from "./translations/en/common.json";
// import common_no from "./translations/no/common.json";

// i18next.init({
//     interpolation: { escapeValue: false },  // React already does escaping
//     lng: 'en',                              // language to use
//     resources: {
//         no: {
//             common: common_no               // 'common' is our custom namespace
//         },
//         en: {
//             common: common_en               // 'common' is our custom namespace
//         },
//         de: {
//             common: common_de
//         },
//     },
//   });
// ReactDOM.render(<App />, document.getElementById('root'));

//ReactDOM.render(
ReactDOM.hydrate(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <App/>
      </BrowserRouter>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
