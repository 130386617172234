import React from 'react';
import PropTypes from 'prop-types';


/**
 * Skriver ut en ingress hvis det kommer noe tekst inn her
 *
 */


export const ingressPropType = PropTypes.shape({
    ingressText: PropTypes.string,
}).isRequired;

const Ingress = ({
    ingressText
}) => {
  if(ingressText !== '') {
    return <div className="card-title kw-ingress">
            {ingressText}
        </div>;
    } else {
        return '';
    }
};

Ingress.propTypes = ingressPropType;

export default Ingress;
