import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
 
const FbShare = (props) => {
    const { isLoading, isError } = useContext(TeamContext);
    const { t } = useTranslation();

    const fbLink = "https://www.facebook.com/sharer/sharer.php?u=" + String(props.linkToShare) + ";src=sdkpreparse";
    
    function Content() {
        return (
            <Row className="justify-content-center pt-2">
                <div className="fb-share-button" 
                     data-href={props.linkToShare} 
                     data-layout="button_count" 
                     data-size="large">
                         <a target="_blank" 
                            href={fbLink} 
                            rel="noopener noreferrer"y
                            
                            className="fb-xfbml-parse-ignore">
                             {t('fb_Share')}
                        </a>
                </div>
            </Row>
        )
    };
    
    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
            
        ) : (
            <div className='kw-card'>
                <Content />
            </div>
        )
     );
}

export default FbShare;