import React, { useContext, useState, useEffect } from 'react';
import KwImage from "../../atomic/atoms/Kw_Image";
import Ingress from '../../atomic/atoms/Ingress';
import ArticleText from '../../atomic/atoms/ArticleText';
import { TeamContext } from "../contexts/TeamContext";
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope } from '@fortawesome/free-regular-svg-icons';
// import FbShare from '../facebook/FbShare';
import FbLike from '../facebook/FbLike';

const NewsDetails = ({ article, forceShowText }, context) => {
    const { activeTeam, isLoading, isError } = useContext(TeamContext);
    const history = useHistory();
    const [shareLink, setShareLink] = useState('');
    
    if (forceShowText) {
        article.view=1;
    };

    useEffect(() => {
        let tmpLink  = window.location.host + "/news/" + article.newsId;

        if(activeTeam?.team?.id) {
            process.env.NODE_ENV === "development" && (
                tmpLink = `https://klubbweb.no/?cmd=news_show_one&lag=${activeTeam.team.id}&activeId=${article.newsId}`
            );
        };
        // process.env.NODE_ENV === "development" && (
        //     tmpLink = "https://klubbweb.no/?cmd=news_show_one&lag=106&activeId=" + article.newsId
        // );
        setShareLink(tmpLink);

        //setShareLink(window.location.host + "/news/" + article.newsId);
        //setShareLink("https://klubbweb.no/?cmd=news_show_one&lag=106&activeId=" + article.newsId);
    }, [activeTeam, article.newsId]);

    function Content() {
        return (
            <div className={`card ${activeTeam.page.design.stylesheet}`}>
                <KwImage 
                    showImage={article.articleImage.format === 'L'}
                    bildeId={article.articleImage.id}
                    alt={article.title}
                    classes='rounded card-img-top'
                />
                <div className="card-body">
                    <div className="card-content grey-text text-darken-3">
                        <h1 className="card-title">{article.title}</h1>
                        <div className="underlined">
                            <small>
                                <FontAwesomeIcon icon={faCalendarAlt} /> {article.dateCreated}
                                &nbsp;
                                <FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${article.author.userId}`}>{article.author.alias}</a>
                                &nbsp;
                            </small>
                        
                            <span className="badge badge-danger right">{article.dateChanged}</span>
                            {/* <FbShare linkToShare={shareLink}/> */}
                            <FbLike linkToShare={shareLink} buttonSize="small"/>
                        </div> 
                        <KwImage 
                            showImage={article.articleImage.format === 'P'}
                            bildeId={article.articleImage.id}
                            alt={article.title}
                            classes='rounded kw-image-article-content'
                        />
                        
                        <Ingress className="card-title kw-ingress" ingressText= { article.ingress} />
                        <ArticleText 
                            teamId = { activeTeam.team.id }
                            text = { article.fulltext}
                            view = { article.view } 
                            articleId = { article.newsId }
                            article = {article}
                        />
                    </div>
                </div>
            </div>
        )
    };

    function redirect() {
        history.push('/news/' + article.newsId + '?lag=' + activeTeam.team.id);
    };
    
    return ( 
        isLoading && !isError ? (
            <div>
                <Spinner animation="border" variant="secondary" size="sm" />
            </div>
            
        ) : (
            article.view === 2 ? (
                <div className='kw-card kw-newslink' onClick={redirect}>
                    <Content />
                </div>
            ) : (
                <div className='kw-card'>
                    <Content />
                </div>
            )
        )
     );
}

export default NewsDetails;