import React, { Fragment } from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import ProductRow from './ProdctRow';
import Spinner from 'react-bootstrap/Spinner';
import KwPagination from '../../atomic/organisms/KwPagination';

const CompareProductList = ({ data, isLoading, isError,  
                              currencyData, isCurrencyLoading, isCurrencyError,
                              section, setSection, maxRows
}) => {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    return (
        <Fragment>

            <div className="alert alert-success text-center">
                <strong>{t(`${langFiles}.titles.ExistBothPlaces`)}</strong>
            </div>



            {isCurrencyError ? (
                <div>
                    {t('could_not_retrieve_data')}
                </div>
            )   : (
                isCurrencyLoading && !isCurrencyError ? (
                    <div>
                        <Spinner animation="border" variant="secondary" size="sm" /> {t(`${langFiles}.Retrieving_currency`)}
                    </div>
                ) : (
                    <div>
                        {/* Rad 1 av overskrift */}
                        <div className="row d-none d-sm-flex bold">
                            {/* Venstre */}
                            <div className="col-sm-6 col-md-7 col-lg-8">
                                &nbsp;
                            </div>
                            {/* Høyre */}
                            <div className="col-sm-6 col-md-5 col-lg-4">
                                <div className ="col-12 text-center">
                                    {/* <h6 className="d-none d-xl-inline-flex">{t(`${langFiles}.titles.AllPricesInNOK`)} (100 {currencyData.currency.baseCur} = {currencyData.currency.obsValue} {currencyData.currency.quoteCur})xxx</h6>
                                    <h6 className="d-xl-none"><small>{t(`${langFiles}.titles.AllPricesInNOK`)} (100 {currencyData.currency.baseCur} = {currencyData.currency.obsValue.toLocaleString('no-NO', {minimumFractionDigits: 2})} {currencyData.currency.quoteCur})</small>yyy</h6> */}
                                    <h6><small>{t(`${langFiles}.titles.AllPricesInNOK`)} (100 {currencyData.currency.baseCur} = {currencyData.currency.obsValue.toLocaleString('no-NO', {minimumFractionDigits: 2})} {currencyData.currency.quoteCur})</small></h6>
                                </div>
                                <div className ="row">
                                    <div className="col-sm-6 text-center">
                                        {t(`${langFiles}.titles.Price`)}
                                        <hr />
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        {t(`${langFiles}.titles.PriceDifference`)}
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Rad 2 av overskrift */}
                        <div className="row d-none d-sm-flex underlined bold">
                            {/* Venstre */}
                            <div className="col-sm-6 col-md-7 col-lg-8">
                                <div className ="row">
                                    <div className="col-sm-2">
                                        &nbsp;
                                    </div>
                                    <div className="col-sm-4 col-md-3">
                                        &nbsp;
                                    </div>
                                    <div className="col-sm-3 d-sm-none d-md-flex">
                                        {t(`${langFiles}.titles.Country`)}/{t(`${langFiles}.titles.District`)}
                                    </div>
                                    <div className="col-sm-4 col-md-3 xd-sm-none d-md-block">
                                        {t(`${langFiles}.titles.Product_type`)}
                                    </div>
                                    <div className="col-sm-2 col-md-1 text-center">
                                        {t(`${langFiles}.titles.Cl`)}
                                    </div>
                                </div>
                            </div>
                            {/* Høyre */}
                            <div className="col-sm-6 col-md-5 col-lg-4">
                                <div className ="row">
                                    <div className="col-sm-3 text-center">
                                        {t(`${langFiles}.titles.Norway`)}
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        {t(`${langFiles}.titles.Sweden`)}
                                    </div>
                                    <div className="col-sm-3 text-center">
                                        {t(`${langFiles}.titles.PrVolume`)}
                                    </div>
                                    <div className="col-sm-3 text-right">
                                        {t(`${langFiles}.titles.PrLitre`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}

            {isError ? (
                <div>
                    {t('could_not_retrieve_data')}
                </div>
            )   : (
                isLoading && !isError ? (
                    <div>
                        <Spinner animation="border" variant="secondary" size="sm" /> {t(`${langFiles}.Retrieving_products`)}
                    </div>
                ) : (
                    data.products?.length > 0 ? (
                        <div>
                            {data.products?.map(item => (
                                <ProductRow key={item.productNumber} product={item} currencyData={currencyData} setSection={setSection} activeSection={section} />
                            ))}

                            <KwPagination setSection={setSection} maxRows={maxRows} totalRows={data.nbrOfRows} activeSection={section} />
                        </div>
                    ) : (
                        <div className="underlined">
                            <div className="text-info text-center">
                                {t(`${langFiles}.No_hits_on_connected_products`)}
                            </div>
                        </div>
                    )
                )
            )}
        </Fragment>
    );
}
 
export default withTranslation('common')(CompareProductList);