import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

const apiUrl = `${process.env.REACT_APP_DOMAIN}/no.klubbweb.ImageServlet`;


/**
 * Skriver ut en Html tekst hvis det kommer noe tekst inn her
 *
 */


export const htmlTextPropType = PropTypes.shape({
    ingressText: PropTypes.string,
}).isRequired;

const HtmlText = ({
    text,
    view = 1,
    articleId,
    teamId,
    article
}) => {
    const showAll = view===1;
    var textAsHtml = atob(text);
    // const { t } = useTranslation();
    textAsHtml = textAsHtml.replace(/\/no.klubbweb.ImageServlet/g, `${apiUrl}`);

    if(text !== '') {
        if (showAll) {
            return (
                <div dangerouslySetInnerHTML={{__html: textAsHtml}} />
            );
        } else {
            return "";
        }
    } else {
        return '';
    }
};

HtmlText.propTypes = htmlTextPropType;

// export default HtmlText;
export default withTranslation('common')(HtmlText);
