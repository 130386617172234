/*
 * Har funnet noe her : https://dev.to/ksushiva/how-to-translate-your-react-js-app-with-i18next-12mn
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { se, en, no } from './locales';

const fallbackLng = ['en']; 
const availableLanguages = ['en', 'no', 'se'];


const options = {
  fallbackLng,
  whitelist: availableLanguages,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  debug: true,

  // lng: 'en',

  // resources: {
  //   pt: {
  //     common: pt['pt-BR'],
  //   },
  //   en: {
  //     common: en.en,
  //   },
  // },
  resources: {
    no: {
        common: no               // 'common' is our custom namespace
    },
    se: {
        common: se
    },
    en: {
        common: en               // 'common' is our custom namespace
    },
},

  // fallbackLng: 'no',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options)

//   .changeLanguage('en', (err, t) => {
//     if (err) return console.log('something went wrong loading', err);
//   });

export default i18n;