import React from 'react';
import { useTranslation, withTranslation } from "react-i18next";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

function SortingSelector({sortingRadioValue, setSortingRadioValue}) {
    const langFiles = 'pricecompare';
    const { t } = useTranslation();

    const radios = [
        { name: `${t(`${langFiles}.sorting.Price_difference`)}`, value: 'DIFF' },
        { name: `${t(`${langFiles}.sorting.Alphabetical`)}`, value: 'ALPHA' },
    ];

    return (
        <>
        <ButtonGroup toggle>
            {radios.map((radio, idx) => (
            <ToggleButton
                key={idx}
                type="radio"
                variant="light"
                name="radio"
                value={radio.value}
                checked={sortingRadioValue === radio.value}
                onChange={(e) => setSortingRadioValue(e.currentTarget.value)}
            >
                {radio.name}
            </ToggleButton>
            ))}
        </ButtonGroup>
        </>
    );
}

export default withTranslation('common')(SortingSelector);
