import React, { createContext } from "react";
import useDataApi from '../api/DataApi';

/* Denne filen er lagd etter oppskriften her:
 * https://www.codementor.io/@sambhavgore/an-example-use-context-and-hooks-to-share-state-between-different-components-sgop6lnrd
 * og kraftig etterjustert etter denne: https://www.youtube.com/watch?v=CGRpfIUURE0&list=PL4cUxeGkcC9hNokByJilPg5g9m2APUePI&index=3
 */

export const TeamContext = createContext();

const TeamContextProvider = props => {
  const {
    children
  } = props;

  // Finner data for laget
  // const queryString = window.location.search;
  let queryString;
  if (typeof window !== "undefined") {
    //Kan ikke sjekke på window på serveren når man bruker ssr. 
    //Window kan ikke sjekkes før koden kjører på klienten.
    queryString = window.location.search;
  }

  const urlParams = new URLSearchParams(queryString);
  let teamId = urlParams.get("lag");
  if(teamId === null || teamId === "") {
    teamId = 0;
  }

  // const [{ data }] = useDataApi(
  //     `teamMenu/getTeamMenu?teamId=${teamId}`,
  //     { activeTeam: {} },
  // );
  
  const [{ data, isLoading, isError }] = useDataApi(
      `teamMenu/getTeamMenu?teamId=${teamId}`,
      { activeTeam: {} },
  );

//   const [{ data, isLoading, isError }, changeParams] = useDataForTeamApi(
//     'news/getActiveNewsForTeamAndClub',
//     []
// );

  const teamContext = {
    teamId,
    activeTeam: data.activeTeam.thisTeam,
    subTeams: data.activeTeam.subTeams,
    isActiveTeamLoading: isLoading, 
    isActiveTeamError: isError
  }

  return (
    <TeamContext.Provider value={{...teamContext}}>
      {children}
    </TeamContext.Provider>
  );
}

export default TeamContextProvider;